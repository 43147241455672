import { WalletTypeType } from "api/walletGroup";
import { Roles } from "contexts/AuthContext/utils/enums";
import { WorkingWalletContext } from "modules/Wallets/contexts/WorkingWalletContext";
import DebugIcon from "modules/Wallets/WorkingWallets/components/Actions/icons/DebugIcon";
import DisableIcon from "modules/Wallets/WorkingWallets/components/Actions/icons/DisableIcon";
import EditIcon from "modules/Wallets/WorkingWallets/components/Actions/icons/EditIcon";
import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import RoleContext from "src/contexts/AuthContext";

import styles from "./Actions.module.scss";

type ActionsProps = {
	readonly hash_id: WalletTypeType[`hash_id`];
	readonly identifier: WalletTypeType[`identifier`];
	readonly type: WalletTypeType[`type`];
	readonly currency: WalletTypeType[`currency`][`code`];
};

const Actions = ({ hash_id, identifier, type, currency }: ActionsProps) => {
	//TODO: Завести в ui-kit серую кнопку и юзать тут кнопки из кита

	const { openDisableWalletModal, openEditWalletModal } = useContext(WorkingWalletContext);
	const { hasRole } = useContext(RoleContext);

	const editPermission = hasRole(Roles.WALLET_UPDATE);
	const logListPermission = hasRole(Roles.LOGS);

	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleEdit = (e: React.MouseEvent) => {
		e.stopPropagation();
		openEditWalletModal(hash_id);
	};

	const handleDisable = (e: React.MouseEvent) => {
		e.stopPropagation();
		openDisableWalletModal(hash_id);
	};

	//TODO: Поменять ссылку когда появится страница
	const handleDebug = (e: React.MouseEvent) => {
		e.stopPropagation();
		navigate(`/logs?identifier=${identifier}&page=1&limit=10`);
	};

	const handleNavigate = () => {
		localStorage.setItem(
			`pluginConstructor`,
			JSON.stringify({
				w_hash_id: hash_id,
				wallet_type: type,
				currency_code: currency,
				label: `label`,
			})
		);
		navigate("/payment-systems/plugin");
	};

	return (
		<div className={styles.wrapper}>
			{editPermission && (
				<Button data-test-id="editButon" onClick={handleEdit} variant="secondary" dataTestId="editButon">
					<EditIcon />
					<div className={styles.label}>{t("Edit")}</div>
				</Button>
			)}
			{logListPermission && (
				<Button data-test-id="debuggingButton" onClick={handleDebug} variant="secondary" dataTestId="debuggingButton">
					<DebugIcon />
					<div className={styles.label}>{t("Debug")}</div>
				</Button>
			)}
			{hasRole(Roles.MANAGE_PAYMENTS_CONSTRUCTOR) && (
				<Button
					data-test-id="showOnPluginButton"
					onClick={handleNavigate}
					variant="secondary"
					dataTestId="showOnPluginButton"
				>
					<div className={styles.label}>{t("Show on plugin")}</div>
				</Button>
			)}
			{hasRole(Roles.WALLET_SET_ACTIVE) && (
				<Button data-test-id="switchOffButton" onClick={handleDisable} variant="delete" dataTestId="switchOffButton">
					<DisableIcon />
					<div className={styles.label}>{t("Disable")}</div>
				</Button>
			)}
		</div>
	);
};

export default Actions;
