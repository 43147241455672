import { useTranslation } from "pay-kit";
import Filter from "components/Filter";
import ReloadButton from "modules/Postbacks/components/ReloadButton";
import { useIntersectionObserver } from "utils/hooks/useIntersectionObserver";
import { withdrawalsRequestPageColumns } from "modules/TransferHistory/components/WithdrawalsHistoryTabs/components/WithdrawalsRequestPageColumns";
import { useOperationHistoryContext } from "modules/TransferHistory/contexts/OperationHistoryContext";
import { Table } from "pay-kit";
import { useState } from "react";

import styles from "./withdrawalsRequestPage.module.scss";

const WithdrawalsRequestPage = () => {
	const { getWithdrawalsList, withdrawalList, isLoading } = useOperationHistoryContext();
	const [filter, setFilter] = useState({});
	// const intersectionObserverAPI = useIntersectionObserver({
	// 	list: withdrawalList,
	// 	offset: 5,
	// 	isObserverUnMounting: true,
	// });

	return (
		<div>
			{/* <Filter
				classNames={{
					wrapper: styles.statusFilterWrapper,
				}}
				values={filter}
				fields={[
					{
						name: "status",
						placeholder: {t("Status")},
						component: "select",
						className: styles.statusSelectWrapper,
						options: [
							{
								label: "Успешный",
								value: "1",
							},
							{
								label: "Отклоненный",
								value: "2",
							},
							{
								label: {t("New")},
								value: "0",
							},
						],
					},
					{
						name: "date",
						placeholder: {t("Date")},
						component: "date",
						className: styles.dateWrapper,
						dateTimeFormat: "YYYY-MM-DD",
					},
				]}
				onApply={(values) => setFilter(values)}
			/> */}

			<div className={styles.reloadButtonWrapper}>
				<ReloadButton data-test-id="X2TtBxq5dYo1w58-tRmF5" isLoading={isLoading} onClick={getWithdrawalsList} />
			</div>

			<Table
				className={styles.tableWrapper}
				rowKey={"id"}
				data={withdrawalList}
				columns={withdrawalsRequestPageColumns}
				isLoading={isLoading}
			/>

			{/* <div ref={intersectionObserverAPI.targetRef} className={styles.target} /> */}
		</div>
	);
};

export default WithdrawalsRequestPage;
