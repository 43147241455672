import { UpdateSettingsType } from "api";
import { upiErrors } from "modules/AppSettings/AppSettingsSystem/components/PaymentMethodBlockErrors/helpers";
import { Alert } from "pay-kit";
import { FC, useEffect, useState } from "react";

import styles from "./paymentMethodBlockErrors.module.scss";

const PaymentMethodBlockErrors: FC<PaymentMethodBlockErrorsType> = ({ formState, checkError }) => {
	const [errors, setErrors] = useState<readonly string[]>([]);

	const paymentMethods = [
		{
			methods: formState.upi_p2p_payment_methods,
			methodTitle: `UPI P2P`,
			methodsCount: formState.upi_p2p_payment_methods.length,
		},
		{
			methods: formState.upi_api_payment_methods,
			methodTitle: `UPI API`,
			methodsCount: formState.upi_api_payment_methods.length,
		},
	];

	useEffect(() => {
		if (formState.upi_p2p_payment_methods.length || formState.upi_api_payment_methods.length) {
			const errors = upiErrors(paymentMethods) as readonly string[];
			checkError && checkError(!!errors.find((elem) => elem));
			setErrors(errors);
		}
	}, [formState.upi_p2p_payment_methods, formState.upi_api_payment_methods]);

	return (
		<div className={styles.paymentMethodBlockErrors}>
			{errors.map((error, i) => error && <Alert title={error} key={i} variant="danger" />)}
		</div>
	);
};

export default PaymentMethodBlockErrors;

type PaymentMethodBlockErrorsType = {
	readonly formState: UpdateSettingsType;
	readonly checkError?: (isError: boolean) => void;
};
