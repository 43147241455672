/* eslint-disable ternary/no-unreachable */
import { fetchApi } from "api/fetch";

const reportsGroup = {
	// список отчетов
	getReports: () => fetchApi("/reports", {}, "GET"),
	// список кошельков
	getWallets: (type?: string) => fetchApi("/wallets", { removed: 0, active: 1, type }, "GET"),
	// Сумма транзакций (detail = true : детализация по дням)
	createTransactionsSumReport: (data: SumReportReqData, detail: boolean) =>
		fetchApi(`/reports/transactions/${detail ? "sum-detail" : "sum"}`, data, "POST"),
	// Список транзакций
	createTransactionsListReport: (data: ListReportReqData) => fetchApi("/reports/transactions/list", data, "POST"),
	// Сумма транзакций, по которым завершено накопление (detail = true : детализация по дням)
	createTransactionsCompletedSumReport: (data: CompletedSumReportReqType, detail: boolean) =>
		fetchApi(`/reports/transactions/completed-sum${detail ? "-detail" : ""}`, data, "POST"),
	// Список транзакций, по которым завершено накопление
	createTransactionsCompletedListReport: (data: CompletedListReportReqType) =>
		fetchApi("/reports/transactions/completed-list", data, "POST"),
	// Список заявок на вывод
	createWithdrawalsReport: (data: WithdrawalsReportReqType) => fetchApi("/reports/withdrawals", data, "POST"),
	// История отображения данных счета лейблу
	createLabelStatisticsReport: (data: LabelStatisticsReportReqType) =>
		fetchApi("/reports/label-statistics", data, "POST"),
	// Сумма комиссий
	createCommissionReport: (data: CommissionReportReqType) => fetchApi("/reports/commission", data, "POST"),
	// Операции с балансом сеттлемента
	createSettlementOperationsReport: (data: SettlementOperationsReportReqType) =>
		fetchApi("/reports/settlement-operations", data, "POST"),
	createBalanceOperationsReport: (data: BalanceOperations) => fetchApi("/reports/balance-operations", data, "POST"),
	// Авторепорты (Задачи)
	getAutoReports: () => fetchApi("/autoreport", undefined, "GET"),
	// Get autoreport
	getAutoReport: (id: number) => fetchApi<GetAutoReportResponseData>(`/autoreport/${id}`, undefined, "GET"),
	// Delete autoreport
	deleteAutoReport: (id: number) => fetchApi(`/autoreport/${id}`, undefined, "DELETE"),
	// Create autoreport
	createAutoReport: (data: CreateAutoReportReqType) => fetchApi("/autoreport", data, "POST"),
	// Update autoreport
	updateAutoReport: (id: number, data: CreateAutoReportReqType) => fetchApi(`/autoreport/${id}`, data, "PUT"),
};

export default reportsGroup;

export type BalanceOperations = {};

export enum ReportStatus {
	NEW = 2,
	IN_PROGRESS = 0,
	SUCCESS = 1,
	FAIL = 3,
}

export type ReportsListItemType = {
	readonly created_at: string;
	readonly processed_at: string;
	readonly status: ReportStatus;
	readonly file_name: string;
	readonly file_path: string;
};

export type AutoReportListItemType = {
	readonly id: number;
	readonly emails: readonly string[];
	readonly start: string;
	readonly file_format: string;
	readonly timezone: boolean;
	readonly author: string;
	readonly report_type: "transactions_sum" | "transactions_list" | "transactions_sum-detail";
	readonly reporting_period: "day" | "week" | "month" | "quarter" | "year";
	readonly currency_code: string;
};

export type SumReportReqData = {
	// readonly widget: "reports";
	readonly report_currency_code: string;
	readonly file_format: "xlsx" | "csv";
	readonly filters: {
		readonly wallet_type?: string;
		readonly direction?: "ingoing" | "outgoing";
		readonly exchanger_identifier?: string;
		readonly label?: string;
		readonly creation_type?: "auto" | "manual";
		readonly originality?: boolean;
		readonly status?: 1 | 0;
		readonly type: readonly ("0" | "1" | "2")[];
		readonly created_from?: string;
		readonly created_to?: string;
		readonly activated_from?: string;
		readonly activated_to?: string;
	};
	readonly fields?: readonly ("total_commission" | "deposit_commission" | "withdrawal_commission")[];
	readonly utc_0?: boolean;
};

export type ListReportReqData = {
	// readonly widget: "reports";
	readonly file_format: "xlsx" | "csv";
	readonly utc_0?: boolean;
	readonly filters: {
		readonly wallet_type?: string;
		readonly wallet_id?: string;
		readonly direction?: "ingoing" | "outgoing";
		readonly creation_type?: "auto" | "manual";
		readonly originality?: boolean;
		readonly type?: readonly ("0" | "1" | "2")[];
		readonly status?: 1 | 0;
		readonly label?: string;
		readonly exchanger_identifier?: string;
		readonly created_from?: string;
		readonly created_to?: string;
		readonly activated_from?: string;
		readonly activated_to?: string;
		readonly archiving_status?: "onlyArchived" | "withoutArchived";
		readonly archiving_date_from?: string; // visible if archiving_status === onlyArchived | undefined
		readonly archiving_date_to?: string;
		readonly only_archived?: boolean;
	};
	readonly fields: readonly (
		| "wallet_identifier"
		| "transaction_number"
		| "vendor_code"
		| "merchant_order_id"
		| "exchanger_identifier"
		| "total_commission"
		| "date"
		| "activated_date"
		| "label"
		| "status"
		| "type"
		| "creation_type"
		| "archiving_date"
		| "originality"
	)[];
};

export type CompletedSumReportReqType = {
	readonly fields?: readonly ("total_commission" | "deposit_commission" | "withdrawal_commission")[];
	readonly file_format: "xlsx" | "csv";
	readonly utc_0: boolean;
	readonly filters: {
		readonly wallet_type?: string;
		readonly wallet_id?: string;
		readonly direction?: "outgoing" | "ingoing";
		readonly type?: readonly ("0" | "2" | "1")[];
		readonly creation_type?: "auto" | "manual";
		readonly label?: string;
		readonly exchanger_identifier?: string;
		readonly postback_status?: 0 | 1;
		readonly stockpiling_date_from: string;
		readonly stockpiling_date_to: string;
	};
	readonly report_currency_id?: number;
	readonly report_currency_code?: string;
	readonly detail_by_day?: boolean;
};

export type CompletedListReportReqType = {
	readonly filters: {
		readonly wallet_type?: string;
		readonly wallet_id?: string;
		readonly direction?: "outgoing" | "ingoing";
		readonly type?: readonly ("0" | "2" | "1")[];
		readonly creation_type?: "auto" | "manual";
		readonly label?: string;
		readonly exchanger_identifier?: string;
		readonly postback_status?: 0 | 1;
		readonly stockpiling_date_from?: string;
		readonly stockpiling_date_to?: string;
		readonly archiving_status?: "onlyArchived" | "withoutArchived";
		readonly archiving_date_from?: string; // visible if archiving_status === onlyArchived | undefined
		readonly archiving_date_to?: string;
	};
	readonly utc_0: boolean;
	readonly file_format: "xlsx" | "csv";
	readonly fields: readonly (
		| "wallet_identifier"
		| "transaction_number"
		| "vendor_code"
		| "merchant_order_id"
		| "exchanger_identifier"
		| "total_commission"
		| "stockpiling_date"
		| "label"
		| "type"
		| "creation_type"
		| "archiving_date"
		| "postback_status"
		| "stockpiling_id"
	)[];
};

export type WithdrawalsReportReqType = {
	readonly file_format: "xlsx" | "csv";
	readonly wallet_type?: string; // Тип кошелька
	readonly currency_code?: string; // Валюта
	readonly status?: 0 | 3 | 2 | 1 | 5 | 6 | 8 | 4 | 9; // Статус заявки
	readonly postback_status?: 1 | 2; // Статус постбека
	readonly label?: string; // Лейбл
	// Период
	readonly date_from?: string;
	readonly date_to?: string;
};

export type LabelStatisticsReportReqType = {
	readonly report_type: "label_statistics";
	readonly file_format: "xlsx" | "csv";
	readonly wallet_type?: string;
	readonly label?: string;
	readonly identifier?: string;
	readonly date_from?: string;
	readonly date_to?: string;
};

export type CommissionReportReqType = {
	readonly file_format: "xlsx" | "csv";
	readonly date_from?: string;
	readonly date_to?: string;
};

export type SettlementOperationsReportReqType = {
	readonly file_format: "xlsx" | "csv";
	readonly wallet_type?: string;
	readonly operation_type?: "ingoing" | "outgoing";
	readonly currency_code?: string;
	readonly date_from?: string;
	readonly date_to?: string;
};

export type CreateAutoReportReqType = {
	readonly report_type: "transactions_sum" | "transactions_list" | "transactions_sum-detail";
	readonly filters: {
		readonly wallet_type?: string;
		readonly wallet_hash_id?: string;
		readonly direction?: "ingoing" | "outgoing";
		readonly exchanger_identifier?: string;
		readonly label?: string;
		readonly creation_type?: "auto" | "manual";
		readonly originality?: boolean;
		readonly status?: number;
		readonly type?: readonly string[];
		readonly archiving_status?: "onlyArchived" | "withoutArchived";
	};
	readonly report_currency_code?: string;
	readonly fields?: readonly string[];
	readonly file_format: "xlsx" | "csv";
	readonly utc_0: boolean;
	readonly reporting_period: "day" | "week" | "month" | "quarter" | "year";
	readonly emails: readonly string[];
	readonly start: string;
};

export type GetAutoReportResponseData = {
	readonly status: "success" | "fail";
	readonly message: string;
	readonly code: number;
	readonly data: {
		readonly id: number;
		readonly emails: readonly string[];
		readonly start: string; // 05.10.2023 00:00:00 Europe\/Moscow | 04.10.2023 00:00:00 UTC
		readonly file_format: "xlsx" | "csv";
		readonly timezone: boolean;
		readonly author: string;
		readonly report_type: "transactions_list" | "transactions_sum-detail" | "transactions_sum";
		readonly reporting_period: "day" | "week" | "month" | "quarter" | "year";
		readonly currency_code: string;
		readonly fields?: Record<string, string> | readonly string[]; // yes, sometimes array, sometimes object
		readonly filters: {
			readonly direction?: "ingoing" | "outgoing";
			readonly type?: readonly ("0" | "1" | "2")[];
			readonly label?: string;
			readonly status?: 0 | 1;
			readonly wallet_id?: number;
			readonly originality?: boolean;
			readonly wallet_type?: string;
			readonly creation_type?: "auto" | "manual";
			readonly exchanger_identifier?: string;
			readonly archiving_status?: "onlyArchived" | "withoutArchived";
		};
	};
	readonly paginate: {
		readonly offset: number;
		readonly total: number;
		readonly limit: number;
	};
};
