import useUpdateToDefaultSorting from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/SavingDisplayOrder/hooks/useUpdateToDefaultSorting";
import { usePSDisplayOrderContext } from "modules/AppSettings/Plugin/components/PSDisplayOrder/context";
import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";

import styles from "./savingDisplayOrder.module.scss";

const SavingDisplayOrder = () => {
	const psDisplayOrderContextAPI = usePSDisplayOrderContext();
	const updateToDefaultSorting = useUpdateToDefaultSorting();
	const { t } = useTranslation();

	const cancel = () => {
		psDisplayOrderContextAPI?.openSavingDisplayOrderModal(false);
		psDisplayOrderContextAPI?.openDisplayOrderModal(true);
	};

	const save = () => {
		if (psDisplayOrderContextAPI?.defaultSortingData)
			updateToDefaultSorting.update(psDisplayOrderContextAPI?.defaultSortingData);
		psDisplayOrderContextAPI?.openSavingDisplayOrderModal(false);
	};

	return (
		<div className={styles.savingDisplayOrder}>
			<div className={styles.infoHeader}>{t(`The custom display order is the same as the default order.`)}</div>

			<div className={styles.questioningHeader}>{t(`Are you sure you want to use the default order?`)}</div>

			<div className={styles.actionsGroup}>
				<Button data-test-id="Q7NFU0z_An_QUGv2zOKKm" variant="secondary" onClick={cancel}>
					{t("Cancel")}
				</Button>

				<Button data-test-id="tudnzwENC4OliJCUG3jr7" onClick={save}>
					{t("Save")}
				</Button>
			</div>
		</div>
	);
};

export default SavingDisplayOrder;
