// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SortableElements__draggableElement-N28{z-index:0;cursor:pointer}.SortableElements__draggableElement-N28 .SortableElements__logoWrapper-Z5s.SortableElements__logoWrapper-Z5s{justify-content:center;align-items:center;border-radius:8px;border:1px solid #dde1e6;background:#fff;min-height:94px}.SortableElements__draggableElement-N28 .SortableElements__logoWrapper-Z5s.SortableElements__logoWrapper-Z5s:hover{border:1px solid #0072c3;background:#fff;box-shadow:0 2px 20px 0 rgba(131,155,176,.3)}.SortableElements__draggableElement-N28 .SortableElements__logoWrapper-Z5s.SortableElements__logoWrapper-Z5s>div{background-position:center;white-space:nowrap;max-width:90px;overflow:hidden;text-overflow:ellipsis}.SortableElements__activeElement-Hpq{z-index:1}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/Plugin/components/PSDisplayOrder/components/SortableElements/sortableElements.module.scss"],"names":[],"mappings":"AAAA,wCACC,SAAA,CACA,cAAA,CAEA,6GACC,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,wBAAA,CACA,eAAA,CACA,eAAA,CAEA,mHACC,wBAAA,CACA,eAAA,CACA,4CAAA,CAGD,iHACC,0BAAA,CAEA,kBAAA,CACA,cAAA,CACA,eAAA,CACA,sBAAA,CAKH,qCACC,SAAA","sourcesContent":[".draggableElement {\n\tz-index: 0;\n\tcursor: pointer;\n\n\t.logoWrapper.logoWrapper {\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tborder-radius: 8px;\n\t\tborder: 1px solid #DDE1E6;\n\t\tbackground: #FFF;\n\t\tmin-height: 94px;\n\n\t\t&:hover {\n\t\t\tborder: 1px solid #0072C3;\n\t\t\tbackground: #FFF;\n\t\t\tbox-shadow: 0 2px 20px 0 rgba(131, 155, 176, 0.30);\n\t\t}\n\n\t\t& > div {\n\t\t\tbackground-position: center;\n\n\t\t\twhite-space: nowrap;\n\t\t\tmax-width: 90px;\n\t\t\toverflow: hidden;\n\t\t\ttext-overflow: ellipsis;\n\t\t}\n\t}\n}\n\n.activeElement {\n\tz-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draggableElement": `SortableElements__draggableElement-N28`,
	"logoWrapper": `SortableElements__logoWrapper-Z5s`,
	"activeElement": `SortableElements__activeElement-Hpq`
};
export default ___CSS_LOADER_EXPORT___;
