import API from "api";
import { UpdateToDefaultSortingType } from "api/currencyGroup";
import { useCallback, useState } from "react";
import { errorsMap } from "utils/enums";


const useUpdateToDefaultSorting = () => {
	const [data, setData] = useState<unknown | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const update: UpdatedHandler = useCallback((params, onError) => {
		setIsLoading(true);
		setError(null);
		setData(null);

		API.currency.updateToDefaultSorting(params).then(
			res => {
				if (res.status === "success") {
					setData(res);
				} else if (res.error_message) {
					window.pushAlert({ type: "error", title: errorsMap.any, description: res.error_message });
				} else {
					// throw new Error("Unexpected response in useUpdateToDefaultSorting");
					throw new Error(errorsMap.anyResponse);
				
				}

				return res;
			}
		).catch((err) => {
			setError(err);
		}).finally(() =>
			setIsLoading(false)
		);

	}, []);

	return {
		update,
		data,
		isLoading,
		error

	};
};

export default useUpdateToDefaultSorting;

type UpdatedHandler = (params: UpdateToDefaultSortingType, onError?: (message?: string) => void) => void
