import RightModal from "components/Modals/RightModal";
import { useTranslation } from "pay-kit";
import { Button, ScrollBox } from "pay-kit";
import React, { useState } from "react";

import ActiveFilters from "./ActiveFilters";
import Field, { FieldPropsType } from "./Field";
import styles from "./Filter.module.scss";

export type FilterPropsType = {
	readonly values: any;
	readonly fields: readonly Omit<FieldPropsType, "value" | "onChange" | "onClear" | "isAdditional">[];
	readonly onApply: (values: any) => void;
	readonly classNames?: {
		readonly wrapper?: string;
		readonly inlineFilter?: string;
		readonly additionalFiltersWrapper?: string;
		readonly additionalFiltersForm?: string;
	};
	readonly maxInlineFields?: number;
	readonly disabled?: boolean;
};

const Filter: React.FC<FilterPropsType> = ({
	values,
	fields,
	onApply,
	classNames,
	maxInlineFields,
	disabled = false,
}) => {
	const { t } = useTranslation();
	const [state, setState] = useState<any>(values);
	const [additionalFiltersIsVisible, setAdditionalFiltersVisibility] = useState<boolean>(false);
	const hasAdditionalFields = !!maxInlineFields && fields.length > maxInlineFields;
	const hasActiveFilters = Object.values(values || {}).filter((value) => value !== undefined).length > 0;

	const onFieldChange = (name: string, value: any) => {
		const newState = { ...state, [name]: value };

		for (const key in newState) {
			if (newState[key] === "" || newState[key] === undefined) {
				delete newState[key];
			}
		}

		setState(newState);
	};

	const onClear = (name?: any) => {
		if (name) {
			onFieldChange(name, undefined);
			onApply(state);
		} else {
			setState({});
			onApply({});
		}
	};

	return (
		<>
			<form
				className={[styles.wrapper, classNames?.wrapper].join(" ")}
				onSubmit={(e) => {
					e.preventDefault();
					onApply(state);
				}}
			>
				<div className={[styles.inlineFilters, classNames?.inlineFilter].join(" ")}>
					{fields
						.filter((field) => !field.hide)
						.map(({ name, ...rest }, n) => {
							if (maxInlineFields && n + 1 > maxInlineFields) {
								return null;
							}

							return (
								<Field
									key={name}
									{...rest}
									name={name}
									value={state?.[name]}
									onChange={(v: any) => {
										onFieldChange(name, v);
									}}
									onClear={() => onClear(name)}
									data-test-id=""
								/>
							);
						})}

					<Button data-test-id="apply" type="submit" disabled={disabled}>
						{t("Submit")}{" "}
					</Button>

					{hasAdditionalFields && !additionalFiltersIsVisible && (
						<Button
							data-test-id="additionalFilters"
							classname={styles.showAdditionalFilters}
							onClick={() => setAdditionalFiltersVisibility(true)}
							variant="secondary"
						>
							{t("More filters")}
						</Button>
					)}
				</div>
				{hasActiveFilters && (
					<ActiveFilters fields={fields} values={values} onFilterClear={onClear} onClearAll={() => onClear()} />
				)}
			</form>

			{hasAdditionalFields && (
				<RightModal
					isVisible={additionalFiltersIsVisible}
					onClose={() => setAdditionalFiltersVisibility(false)}
					onShow={() => setAdditionalFiltersVisibility(true)}
					heading={t("Filters")}
				>
					<div className={[styles.additionalFiltersWrapper, classNames?.additionalFiltersWrapper].join(" ")}>
						<ScrollBox className={styles.scrollBox}>
							<div className={[styles.additionalFiltersForm, classNames?.additionalFiltersForm].join(" ")}>
								{fields
									.filter((field) => !field.hide)
									.map(({ name, ...rest }, n) => (
										<Field
											key={name}
											{...rest}
											name={name}
											value={state?.[name]}
											onChange={(v: any) => onFieldChange(name, v)}
											onClear={() => onClear(name)}
											isAdditional={maxInlineFields ? n + 1 > maxInlineFields : false}
											data-test-id=""
										/>
									))}
							</div>
						</ScrollBox>
						<div className={styles.actions}>
							<Button onClick={() => onClear()} disabled={!hasActiveFilters} variant="secondary" data-test-id="clear">
								{t("Clear")}
							</Button>

							<Button data-test-id={`apply`} onClick={() => onApply(state)}>
								{t("Apply")}
							</Button>
						</div>
					</div>
				</RightModal>
			)}
		</>
	);
};

export default Filter;
