import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { FC } from "react";

import styles from "./iDCol.module.scss";

const IDCol: FC<IDColType> = ({ id, dataTestId }) => {
	const { proxies, setWalletsAmountSideBarState } = useProxyContext();
	const foundProxy = proxies.find((elem) => elem.id === id);
	const amount = foundProxy?.wallets.length || 0;

	return (
		<div
			data-test-id={dataTestId}
			className={styles.iDCol}
			onClick={() =>
				setWalletsAmountSideBarState({
					show: true,
					walletsData: {
						amount,
						ip: foundProxy?.ip || "",
						IDs: foundProxy?.wallets || [],
						status: foundProxy?.status || 0,
					},
				})
			}
		>
			{id}
		</div>
	);
};

export default IDCol;

type IDColType = {
	readonly id: number;
	readonly dataTestId?: string;
};
