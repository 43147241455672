import { t } from "pay-kit";
import * as yup from "yup";

export const isRequired = (value: number | string) => (!value ? t("Required field") : undefined);
// eslint-disable-next-line regexp/no-super-linear-backtracking
// export const isValidAmount = (value: number | string) => /^\d+(?:.\d+)?$/.test(`${value}`) ? undefined : "Неверный формат суммы";
export const isValidAmount = (value: number | string) =>
	/^\d+(?:.\d+)?$/.test(`${value}`) ? undefined : t("Wrong format of the amount");

export const formNumber = () => {
	return yup
		.string()
		// .matches(/^(0*[1-9]\d*(\.\d+)?|0+\.0*[1-9]\d*)$/, "The sum must be greater than zero!")
		.matches(/^(0*[1-9]\d*(\.\d+)?|0+\.0*[1-9]\d*)$/, t("The sum must be greater than zero"))
		.required(t("Required field"));
};

export const formatCrediting: FormatCreditingType = (crediting) => {
	if (!Number.isInteger(crediting)) {
		return parseFloat(crediting.toFixed(2));
	}

	return parseInt(`${crediting}`);
};

export const getFilteredList: GetFilteredListType = ({ list, fieldKey, value }) => {
	return list.filter((elem) => (elem[fieldKey] as string).toUpperCase().includes(value.toUpperCase()));
};

export const convertIntoNumber: ConvertIntoNumberType = (str) => {
	if (str.includes(".")) return parseFloat(str);
	return parseInt(str);
};

export const testAgainstNonNumbers: TestAgainstNonNumbersType = (
	previousValue,
	currentValue,
	maxDigits = 2,
	maxFractionalDigits = 2
) => {
	/** Removes leading zeros from an input string if the number isn't decimal. **/
	const leadingZerosRemovedValue = currentValue.replace(/^0+(?!\.|$)/, "");

	/**
	 * - Validates an input string based on the following criteria:
	 * - Disallows a leading point
	 * - Allows setting the maximum length of the whole number
	 * - Allows setting the maximum length of both parts of a decimal number
	 **/

	const regexPattern = new RegExp(`^(?!\\.)\\d{0,${maxDigits}}(\\.\\d{0,${maxFractionalDigits}})?$`);

	return regexPattern.test(leadingZerosRemovedValue) ? leadingZerosRemovedValue : previousValue;
};

const getAmount: GetAmountType = ({ commission_rate, crediting, ex_rate }) =>
	(100 * crediting * ex_rate) / (100 - commission_rate);

const getAmountForSettlement: GetAmountType = ({ crediting, commission_rate, ex_rate }) =>
	(100 * crediting) / (ex_rate * (100 - commission_rate));

export const getCalcAmount = (isSettlement: boolean): GetAmountType => {
	return isSettlement ? getAmountForSettlement : getAmount;
};

const getCrediting: GetCreditingType = ({ amount, commission_rate, ex_rate }) =>
	(amount - (amount * commission_rate) / 100) / ex_rate;

const getCreditingForSettlement: GetCreditingType = ({ amount, commission_rate, ex_rate }) =>
	(amount - (amount * commission_rate) / 100) * ex_rate;

export const getCalcCrediting = (isSettlement: boolean | undefined): GetCreditingType => {
	return isSettlement ? getCreditingForSettlement : getCrediting;
};

type GetCreditingType = (values: CreditingValues) => number;

type GetAmountType = (values: AmountValues) => number;

type FormatCreditingType = (crediting: number) => number;

type GetFilteredListType = <T>(params: {
	readonly list: ReadonlyArray<T>;
	readonly fieldKey: keyof T;
	readonly value: string;
}) => readonly T[];

type TestAgainstNonNumbersType = (
	previousValue: string,
	currentValue: string,
	maxDigits?: number,
	maxFractionalDigits?: number
) => string;

type ConvertIntoNumberType = (str: string) => number;

type AmountValues = {
	readonly crediting: number;
	readonly commission_rate: number;
	readonly ex_rate: number;
};

type CreditingValues = {
	readonly amount: number;
	readonly commission_rate: number;
	readonly ex_rate: number;
};
