import { TableSelectedRowIds } from "@paykassma/pay-kit/lib/elements/Table/types";
import AuthContext, { Roles, useHasAccessHook } from "contexts/AuthContext";
import ReloadButton from "modules/Postbacks/components/ReloadButton";
import MultipleActions from "modules/Wallets/ArchivedWallets/components/MultipleActions";
import ArchivedWalletContextProvider, { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext";
import styles from "modules/Wallets/Wallets.module.scss";
import { Table, useTranslation } from "pay-kit";
import React, { useContext, useEffect } from "react";
import useURLState from "utils/hooks/useURLState";

import WalletsFilter from "../components/Filter";
import archivedWalletsColumns, { subRow } from "./components/columns";

const ArchivedWallets: React.FC = () => {
	const { wallets, isLoading, selectedHashIDs, setSelectedHashIDs, getWallets, paginationInfo, filter, setFilter } =
		useContext(ArchivedWalletContext);
	const [page, setCurrentPage] = useURLState<number>("page", 1);
	const [limit, setLimit] = useURLState<number>("limit", 100);
	const { hasRole } = useContext(AuthContext);
	const { t } = useTranslation();

	useEffect(() => {
		setFilter({});
	}, []);

	const fetchWallets = () => {
		getWallets({ limit, page });
	};

	const hasListPermission = hasRole(Roles.WALLET_LIST);
	const privateInformationAccess = hasRole(Roles.WALLET_NUMBER);
	const balanceAccess = hasRole(Roles.WALLET_BALANCE);

	useEffect(() => {
		if (hasListPermission) {
			fetchWallets();
		}
	}, [filter, limit, page, hasListPermission]);

	useHasAccessHook({ rule: Roles.ARCHIVED_WALLETS_VIEW, redirect: true });

	return (
		<>
			<div className={styles.gap}>
				<WalletsFilter filter={filter} setFilter={setFilter} type="archived" />
			</div>

			<Table
				rowKey="hash_id"
				data={wallets}
				columns={archivedWalletsColumns(t, privateInformationAccess, balanceAccess)}
				isLoading={isLoading}
				skeleton={{
					rowsCount: 10,
				}}
				subRowRender={subRow}
				className={styles.table}
				select={{
					multiselect: true,
					selectedIds: selectedHashIDs,
					onRowsSelect: (hashIds: TableSelectedRowIds, e?: MouseEvent) => {
						e && e.stopPropagation();
						setSelectedHashIDs(hashIds);
					},
					renderMultipleActions: () => <MultipleActions />,
				}}
				paginator={
					paginationInfo
						? {
							currentPage: page,
							setCurrentPage: (page: number) => setCurrentPage(page as number),
							limit,
							setLimit,
							lastAvailablePage: Math.ceil((paginationInfo.total + paginationInfo.offset) / paginationInfo.limit),
							prefixElement: <ReloadButton data-test-id="" isLoading={isLoading} onClick={fetchWallets} />,
							className: styles.paginator,
							bottomPaginatorProps: {
								className: styles.bottomPaginator,
							},
						  }
						: undefined
				}
			/>
		</>
	);
};

export default (props) => (
	<ArchivedWalletContextProvider>
		<ArchivedWallets {...props} />
	</ArchivedWalletContextProvider>
);
