import { useTranslation } from "pay-kit";
import {
	closestCenter,
	DndContext,
	DragCancelEvent,
	DragEndEvent,
	DragStartEvent,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors
} from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { CurrencyListWithCustomSorting } from "api/settingsGroup";
import { UpdateSortingParamsType } from "api/walletTypeToCurrencyGroup";
import useGetPaymentsByCurrency
	from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/DisplayOrder/hooks/useGetPaymentsByCurrency";
import useUpdateSorting
	from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/DisplayOrder/hooks/useUpdateSorting";
import Grid from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/Grid";
import SortableElements
	from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/SortableElements";
import SortablePSElements
	from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/SortablePSElements";
import { usePSDisplayOrderContext } from "modules/AppSettings/Plugin/components/PSDisplayOrder/context";
import Loader from "modules/AppSettings/Plugin/components/PSHints/components/Loader";
import { ScrollBox } from "pay-kit";
import { FC, useEffect, useState } from "react";

import styles from "./displayOrder.module.scss";

const DisplayOrder: FC<DisplayOrderType> = ({ paymentSystem }) => {
	const {t} = useTranslation();
	const [defaultItems, setDefaultItems] = useState<readonly string[]>([]);
	const [items, setItems] = useState<readonly string[]>([]);
	const [activeId, setActiveId] = useState<string | null>(null);
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
	const getPaymentsByCurrencyAPI = useGetPaymentsByCurrency();
	const psDisplayOrderContextAPI = usePSDisplayOrderContext();
	const updateSortingAPI = useUpdateSorting();

	useEffect(() => {
		if (paymentSystem?.code) getPaymentsByCurrencyAPI.load(paymentSystem.code);
	}, [paymentSystem?.code, getPaymentsByCurrencyAPI.load]);

	useEffect(() => {
		if (getPaymentsByCurrencyAPI.data) {
			const defaultPaymentSystems = getPaymentsByCurrencyAPI.data.default_payment_systems.map(ps => ps.type);
			const appliedPaymentSystems = getPaymentsByCurrencyAPI.data.applied_payment_systems.map(ps => ps.type);
			setDefaultItems(defaultPaymentSystems);
			setItems(appliedPaymentSystems);
		}
	}, [getPaymentsByCurrencyAPI.data]);

	function onDragStart(event: DragStartEvent) {
		setActiveId(event.active?.id as string);
	}

	function onDragEnd(event: DragEndEvent) {
		const { active, over } = event;

		if (active?.id !== over?.id) {
			setItems((items) => {
				const oldIndex = items?.indexOf(active.id as string);
				const newIndex = items?.indexOf(over?.id as string);
				const itemExists = items && oldIndex !== undefined && newIndex !== undefined;

				return itemExists ? arrayMove([...items], oldIndex, newIndex) : items;
			});
		}
		setActiveId(null);
	}

	function onDragCancel() {
		setActiveId(null);
	}

	const areArraysIdentical = items.every((item, index) => item === defaultItems[index]);

	const defaultOrderUpdateData = {
		currencyCode: paymentSystem?.code || ``,
		is_default: true
	};

	const save = () => {

		if (areArraysIdentical) {
			psDisplayOrderContextAPI?.openDisplayOrderModal(false);
			psDisplayOrderContextAPI?.openSavingDisplayOrderModal(true, defaultOrderUpdateData);
		} else if (paymentSystem?.payments) {
			let order = 1;

			const items = defaultItems.map(item => {
				const payment = paymentSystem.payments.find(payment => payment.type === item);
				return ({ id: payment?.id, order: order++ });
			});

			updateSortingAPI.update({ items } as UpdateSortingParamsType);
			psDisplayOrderContextAPI?.openDisplayOrderModal(false);
		}
	};

	const reset = () => {
		psDisplayOrderContextAPI?.openDisplayOrderModal(false);
		psDisplayOrderContextAPI?.openViewOrderResetModal(true, defaultOrderUpdateData);
	};

	const currencyHeader = (
		<div className={styles.currencyHeader}>
			{t("Currency")}<span>{paymentSystem?.code}</span>
		</div>
	);

	return (
		<SortablePSElements save={save} reset={reset} currencyElement={currencyHeader} disableReset={areArraysIdentical}>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragStart={onDragStart}
				onDragEnd={onDragEnd}
				onDragCancel={onDragCancel}
			>
				<Loader loading={getPaymentsByCurrencyAPI.isLoading} className={styles.loading}>
					<SortableContext items={[...(items || [])]} strategy={rectSortingStrategy}>
						<ScrollBox className={styles.scrollBox}>
							<Grid>
								{items?.map((type, index) => (
									<SortableElements key={type} type={type} index={index} activeId={activeId as string} />
								))}
							</Grid>
						</ScrollBox>
					</SortableContext>
				</Loader>
			</DndContext>
		</SortablePSElements>
	);
};

export default DisplayOrder;

type DisplayOrderType = {
	readonly paymentSystem: CurrencyListWithCustomSorting | null
}