import { Button } from "@paykassma/pay-kit";
import useUpdateToDefaultSorting from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/SavingDisplayOrder/hooks/useUpdateToDefaultSorting";
import { usePSDisplayOrderContext } from "modules/AppSettings/Plugin/components/PSDisplayOrder/context";
import { useTranslation } from "pay-kit";

import styles from "./viewOrderReset.module.scss";

const ViewOrderReset = () => {
	const psDisplayOrderContextAPI = usePSDisplayOrderContext();
	const updateToDefaultSorting = useUpdateToDefaultSorting();
	const { t } = useTranslation();

	const onReset = () => {
		if (psDisplayOrderContextAPI?.defaultSortingData)
			updateToDefaultSorting.update(psDisplayOrderContextAPI?.defaultSortingData);
		psDisplayOrderContextAPI?.openViewOrderResetModal(false);
	};
	const onCancel = () => {
		psDisplayOrderContextAPI?.openViewOrderResetModal(false);
	};

	return (
		<div className={styles.viewOrderReset}>
			<div className={styles.questioningHeader}>
				{t("Are you sure you want to reset the display order of payment systems?")}
			</div>

			<div className={styles.actionsGroup}>
				<Button data-test-id="oP4LYj0vnA7EGtjtidIaX" variant="secondary" onClick={onCancel}>
					{t("Cancel")}
				</Button>

				<Button data-test-id="XB9N2_P7jJ7xDLQUE4czo" variant="delete" onClick={onReset}>
					{t("Reset")}
				</Button>
			</div>
		</div>
	);
};

export default ViewOrderReset;
