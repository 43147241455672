import { useTranslation } from "pay-kit";
import { UpdateToDefaultSortingType } from "api/currencyGroup";
import { CurrencyListWithCustomSorting } from "api/settingsGroup";
import DisplayOrder from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/DisplayOrder";
import NewDisplayOrderAddition
	from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/NewDisplayOrderAddition";
import SavingDisplayOrder
	from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/SavingDisplayOrder";
import ViewOrderReset from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/ViewOrderReset";
import { Modal } from "pay-kit";
import { createContext, FC, ReactElement, useCallback, useContext, useMemo, useState } from "react";

import styles from "./context.module.scss";

const PSDisplayOrderContext = createContext<PSDisplayOrderContextType | undefined>(undefined);

const ActionProvider: FC<ActionProviderType> = ({ children }) => {
	const {t} = useTranslation();
	const [isNewDisplayOrderAddition, setIsNewDisplayOrderAddition] = useState(false);
	const [isNewDisplayOrder, setIsNewDisplayOrder] = useState(false);
	const [paymentSystem, setPaymentSystem] = useState<CurrencyListWithCustomSorting | null>(null);
	const [isSavingDisplayOrder, setIsSavingDisplayOrder] = useState(false);
	const [isViewOrderReset, setIsViewOrderReset] = useState(false);
	const [defaultSortingData, setDefaultSortingData] = useState<UpdateToDefaultSortingType | undefined>();

	const openDisplayOrderModal: OpenDisplayOrderModalHandler = useCallback((open, ps) => {
		if (ps) setPaymentSystem(ps);
		setIsNewDisplayOrder(open);
	}, []);

	const openNewDisplayOrderAdditionModal: openNewDisplayOrderAdditionModalHandler = useCallback(() => {
		setIsNewDisplayOrderAddition(true);
	}, []);

	const openSavingDisplayOrderModal: openSavingDisplayOrderModalHandler = useCallback((open, data) => {
		setIsSavingDisplayOrder(open);
		setDefaultSortingData(data);
	}, [defaultSortingData]);

	const openViewOrderResetModal: openViewOrderResetModalHandler = useCallback((open, data) => {
		setDefaultSortingData(data);
		setIsViewOrderReset(open);
	}, []);

	const context = useMemo(() =>
		({
			openDisplayOrderModal,
			openNewDisplayOrderAdditionModal,
			openSavingDisplayOrderModal,
			openViewOrderResetModal,
			defaultSortingData
		}),
	[
		openDisplayOrderModal,
		openNewDisplayOrderAdditionModal,
		openSavingDisplayOrderModal,
		openViewOrderResetModal
	]);

	return (
		<PSDisplayOrderContext.Provider value={context}>
			{children}

			<Modal
				title={t("Display order")}
				onClose={() => setIsNewDisplayOrder(open => !open)}
				isOpen={isNewDisplayOrder}
			>
				<DisplayOrder paymentSystem={paymentSystem} />
			</Modal>

			<Modal
				title={t("Adding display order")}
				onClose={() => setIsNewDisplayOrderAddition(open => !open)}
				isOpen={isNewDisplayOrderAddition}
			>
				<NewDisplayOrderAddition />
			</Modal>

			<Modal
				title={t("Saving display order")}
				onClose={() => setIsSavingDisplayOrder(open => !open)}
				isOpen={isSavingDisplayOrder}
			>
				<SavingDisplayOrder />
			</Modal>

			<Modal
				title={t("Reset display order")}
				onClose={() => setIsViewOrderReset(open => !open)}
				isOpen={isViewOrderReset}
			>
				<ViewOrderReset />
			</Modal>
		</PSDisplayOrderContext.Provider>
	);
};

export default ActionProvider;

export const usePSDisplayOrderContext = () => useContext(PSDisplayOrderContext);

type ActionProviderType = {
	readonly children: ReactElement
}

type PSDisplayOrderContextType = {
	readonly openDisplayOrderModal: OpenDisplayOrderModalHandler
	readonly openNewDisplayOrderAdditionModal: openNewDisplayOrderAdditionModalHandler
	readonly openSavingDisplayOrderModal: openSavingDisplayOrderModalHandler
	readonly openViewOrderResetModal: openViewOrderResetModalHandler
	readonly defaultSortingData?: UpdateToDefaultSortingType
}

type OpenDisplayOrderModalHandler = (open: boolean, ps?: CurrencyListWithCustomSorting) => void
type openNewDisplayOrderAdditionModalHandler = () => void
type openSavingDisplayOrderModalHandler = (open: boolean, data?: UpdateToDefaultSortingType) => void
type openViewOrderResetModalHandler = (open: boolean, data?: UpdateToDefaultSortingType) => void