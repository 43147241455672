import { FC, ReactElement } from "react";

import styles from "./grid.module.scss";

const Grid: FC<GridType> = ({ children }) => {

	return (
		<div className={styles.gridElement}>
			{children}
		</div>
	);
};

export default Grid;

type GridType = {
	readonly children: readonly ReactElement[] | undefined
}