import { PayKitForm } from "@paykassma/pay-kit";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { useTranslation } from "pay-kit";
import { useContext } from "react";
import { fromLocaleDate, notLaterThanToday } from "utils/date";

import { WithdrawalsReportReqType } from "/api/reportsGroup";

import { ActionsContext } from "../../../../../ActionsProvider";
import styles from "./Withdrawal.module.scss";

const Withdrawal = () => {
	const walletsContext = useContext(WalletTypesContext);
	const { createWithdrawalsReportAPI } = useContext(ActionsContext);

	const { t } = useTranslation();

	// wallet types
	const walletTypesOptions = [
		{ label: t("All") },
		...walletsContext.walletTypes.map(({ name, code }) => ({ label: name, value: code })),
	];

	// currencies
	const getCurrenciesOfCurrentWalletType = (wallet_type: undefined | string) =>
		walletsContext.walletTypes
			.find(({ code }) => wallet_type === code)
			?.supported_currencies.map((c) => ({ label: c, value: c })) || [];

	const allCurrencies = walletsContext.walletTypes
		.map((c) => c.supported_currencies)
		.reduce((accum, current) => [...new Set([...accum, ...current])], []);

	const allCurrenciesOptions = allCurrencies.map((c) => ({ label: c, value: c }));

	const FORM_SCHEMA = [
		{
			type: "BaseSettingsSection",
			elements: [
				{
					name: "wallet_type",
					label: t("Wallet type"),
					type: "Select",
					options: walletTypesOptions,
					isLoading: walletsContext.isLoading,
				},
				{
					name: "status",
					label: t("Request status"),
					type: "Select",
					options: [
						{ label: t("All") },
						{ label: t("New"), value: 0 },
						{ label: t("Processing"), value: 3 },
						{ label: t("Canceled"), value: 2 },
						{ label: t("Completed"), value: 1 },
						{ label: t("Rejected"), value: 5 },
						{ label: t("Add beneficiare"), value: 6 },
						{ label: t("Withdrawal error"), value: 8 },
						{ label: t("Check"), value: 4 },
						{ label: t("Waiting for confirmation"), value: 9 },
					],
				},
				{
					name: "currency_code",
					label: t("Currency"),
					placeholder: t("All"),
					type: "Select",
					options: ({ wallet_type }: FormStateType) =>
						wallet_type ? getCurrenciesOfCurrentWalletType(wallet_type) : allCurrenciesOptions,
					isLoading: walletsContext.isLoading,
				},
				{
					name: "postback_status",
					label: t("Postback status"),
					type: "Select",
					options: [{ label: t("All") }, { label: t("Sent"), value: 1 }, { label: t("Not sent"), value: 0 }],
					existsIf: ({ status }: FormStateType) => status === undefined || [1, 4].includes(status),
				},
				{
					name: "date",
					label: t("Create period"),
					type: "DateRangePicker",
					fromPlaceholder: t("From"),
					toPlaceholder: t("To"),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					withTime: true,
					isRequired: true,
					validation: (value: string, { date }: FormStateType) => (!date ? t("Choose period") : undefined),
				},
				{
					name: "date_update",
					label: t("Update period"),
					type: "DateRangePicker",
					fromPlaceholder: t("From"),
					toPlaceholder: t("To"),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					withTime: true,
				},
				{ name: "label", label: t("Label"), type: "TextInput" },
			],
		},
		{
			type: "BottomSection",
			elements: [
				{
					name: "file_format",
					label: t("Report format"),
					type: "Switcher",
					options: [
						{ value: "xlsx", label: "xlsx" },
						{ value: "csv", label: "csv" },
					],
					className: styles.formatSwitcher,
				},
				{
					type: "SubmitButton",
					name: "submitButton",
					label: t("Create"),
					isLoading: createWithdrawalsReportAPI.isLoading,
					onSubmit: (formState: FormStateType) => createWithdrawalsReportAPI.create(prepareFormData(formState)),
				},
			],
		},
	];

	const customElements = {
		BaseSettingsSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.baseSettings}>{children}</div>} />
		),
		BottomSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.actions}>{children}</div>} />
		),
	};

	return (
		<div className={styles.form}>
			<PayKitForm.Builder<FormStateType>
				schema={FORM_SCHEMA}
				initialState={{ file_format: "xlsx" }}
				customElements={customElements}
			/>
		</div>
	);
};

export default Withdrawal;

type FormStateType = {
	readonly wallet_type?: string; // {t("Wallet type")}	readonly status?: 0 | 3 | 2 | 1 | 5 | 6 | 8 | 4 | 9; // {t("Request status")}	readonly currency_code?: string; // {t("Currency")}	readonly postback_status?: 1 | 2; // {t("Postback status")}	// {t("Period")}	readonly date?: {
	readonly label?: string;
	readonly file_format: "xlsx" | "csv";
	readonly currency_code?: string;
	readonly status?: 0 | 3 | 2 | 1 | 5 | 6 | 8 | 4 | 9;
	readonly postback_status?: 1 | 2;
	readonly date_update: {
		readonly from: string;
		readonly to: string;
	};
	readonly date: {
		readonly from: string;
		readonly to: string;
	};
};

type prepareFormDataType = (rawFormData: FormStateType) => WithdrawalsReportReqType;

const prepareFormData: prepareFormDataType = ({
	wallet_type,
	currency_code,
	status,
	postback_status,
	label,
	date,
	file_format,
	date_update,
}) => ({
	wallet_type,
	currency_code,
	status,
	postback_status,
	label,
	file_format,
	date_from: fromLocaleDate(date?.from),
	date_to: fromLocaleDate(date?.to),
	update_date_from: fromLocaleDate(date_update?.from),
	update_date_to: fromLocaleDate(date_update?.to),
});
