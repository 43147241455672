// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Grid__gridElement-Sng{display:grid;grid-template-columns:auto auto auto;grid-gap:16px;margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/Plugin/components/PSDisplayOrder/components/Grid/grid.module.scss"],"names":[],"mappings":"AAAA,uBACC,YAAA,CACA,oCAAA,CACA,aAAA,CACA,eAAA","sourcesContent":[".gridElement {\n\tdisplay: grid;\n\tgrid-template-columns: auto auto auto;\n\tgrid-gap: 16px;\n\tmargin-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridElement": `Grid__gridElement-Sng`
};
export default ___CSS_LOADER_EXPORT___;
