// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DisplayOrder__currencyHeader-_Tt{display:flex;gap:36px;color:#697077;font-size:14px}.DisplayOrder__currencyHeader-_Tt>span{color:#21272a}.DisplayOrder__scrollBox-ydm.DisplayOrder__scrollBox-ydm{max-height:480px;scrollbar-width:thin}.DisplayOrder__loading-rqe{display:flex;justify-content:center;margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/Plugin/components/PSDisplayOrder/components/DisplayOrder/displayOrder.module.scss"],"names":[],"mappings":"AAAA,kCACC,YAAA,CACA,QAAA,CACA,aAAA,CACA,cAAA,CAEA,uCACC,aAAA,CAIF,yDACC,gBAAA,CACA,oBAAA,CAGD,2BACC,YAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":[".currencyHeader {\n\tdisplay: flex;\n\tgap: 36px;\n\tcolor: #697077;\n\tfont-size: 14px;\n\n\t& > span {\n\t\tcolor: #21272A;\n\t}\n}\n\n.scrollBox.scrollBox {\n\tmax-height: 480px;\n\tscrollbar-width: thin;\n}\n\n.loading {\n\tdisplay: flex;\n\tjustify-content: center;\n\tmargin-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currencyHeader": `DisplayOrder__currencyHeader-_Tt`,
	"scrollBox": `DisplayOrder__scrollBox-ydm`,
	"loading": `DisplayOrder__loading-rqe`
};
export default ___CSS_LOADER_EXPORT___;
