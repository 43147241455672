import API from "api";
import { GetPaymentsByCurrencyType } from "api/settingsGroup";
import { useCallback, useState } from "react";
import { errorsMap } from "utils/enums";

const useGetPaymentsByCurrency = () => {
	const [data, setData] = useState<Omit<GetPaymentsByCurrencyType, "ok"> | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const load: LoadHandler = useCallback((currencyCode) => {
		setIsLoading(true);
		setError(null);
		setData(null);

		API.settings
			.getPaymentsByCurrency(currencyCode)
			.then((res) => {
				if (res.status === "ok") {
					setData({
						applied_payment_systems: res.applied_payment_systems,
						default_payment_systems: res.default_payment_systems,
					});
				} else {
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		load,
		data,
		isLoading,
		error,
	};
};

export default useGetPaymentsByCurrency;

type LoadHandler = (currencyCode: string) => void;
