import api from "api/walletGroup";
import { WalletListItem } from "api/walletGroup";
import { useState } from "react";
import { errorsMap } from "utils/enums";

export type getWalletsResultType = {
    readonly load: (type?: string) => void;
    readonly list: readonly WalletListItem[];
    readonly isLoading: boolean;
}

export type getWalletsHookType = () => getWalletsResultType;

const useGetWalletsHook = () => {
	const [list, setList] = useState<readonly WalletListItem[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);

	const load = (type?: string) => {
		setLoading(true);

		api
			.getList({ type, removed: 0, is_active: 1 })
			.then((res: any) => {
				if (res.status !== "success" || !res?.data) {
					throw new Error(errorsMap.anyResponse);
				}

				setList(res.data);

				return res;
			})
			.finally(() => setLoading(false))
			.catch((err) => {
				console.error(err);
				window.pushAlert({ title: err, type: "error" });
			});
	};

	return {
		load,
		list,
		isLoading,
	};
};

export default useGetWalletsHook;
