import API from "api";
import { OldResponse, PaginationData, StandardResponse } from "api/types";
import { WalletListItem, WalletsFilterForm, WalletTypeType } from "api/walletGroup";
import { DeleteWalletModal } from "modules/Wallets/ArchivedWallets/components/modals/DeleteWalletModal";
import { MassDeleteWalletModal } from "modules/Wallets/ArchivedWallets/components/modals/MassDeleteWalletModal";
import { MassRecoverWalletModal } from "modules/Wallets/ArchivedWallets/components/modals/MassRecoverWalletModal";
import { RecoverWalletModal } from "modules/Wallets/ArchivedWallets/components/modals/RecoverWalletModal";
import CommentModal from "modules/Wallets/components/modals/CommentModal";
import { Modal, useTranslation } from "pay-kit";
import { createContext, FC, ReactElement, useEffect, useState } from "react";

export const ArchivedWalletContext = createContext<ArchivedWalletContextType>({} as ArchivedWalletContextType);
ArchivedWalletContext.displayName = "ArchivedWalletContext";

const ArchivedWalletContextProvider: FC<ArchivedWalletContextProviderType> = (props) => {
	const [wallets, setWallets] = useState<readonly WalletListItem[]>([]);
	const [selectedHashIDs, setSelectedHashIDs] = useState<readonly (string | number)[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<WalletsFilterForm>({});
	const [paginationInfo, setPaginationInfo] = useState<null | PaginationData>(null);
	const { t } = useTranslation();

	const [recoverModalData, setRecoverModalData] = useState<WalletModalData>({
		open: false,
		hash_id: `0`,
	});

	const [massRecoverModalData, setMassRecoverModalData] = useState<boolean>(false);

	const [deleteModalData, setDeleteModalData] = useState<WalletModalData>({
		open: false,
		hash_id: `0`,
	});

	const [massDeleteModalData, setMassDeleteModalData] = useState<boolean>(false);

	const [commentModalData, setCommentModalData] = useState<CommentModalData>({
		open: false,
		hash_id: `0`,
		comment: null,
	});

	const getWallets = (params: WalletsFilterForm = {}): Promise<OldResponse<readonly WalletListItem[]>> => {
		setLoading(true);

		const walletsFilters = {
			removed: 1,
		};

		return API.wallet.getList({
				...params,
			  ...filter,
				...walletsFilters,
			}).then((resp: any) => {
				setLoading(false);
				setWallets(resp.data);
				setPaginationInfo(resp.paginate);
				return resp;
			}).finally(() => {
				setLoading(false);
			});
	};

	const recoverWallet = (
		hash_id: WalletTypeType[`hash_id`],
		fetchWallets: boolean,
		parsing_start_at?: string
	): Promise<OldResponse<void>> => {
		return API.wallet.recoverWallet(hash_id, parsing_start_at).then(async (resp) => {
			if (resp.status === "ok" && fetchWallets) {
				await getWallets();
			}

			return resp;
		});
	};

	const deleteWallet = (hash_id: WalletTypeType[`hash_id`]) => {
		return API.wallet.deleteWallet(hash_id).then(async (resp) => {
			if (resp.status === "ok") {
				await getWallets();
			}

			return resp;
		});
	};

	const setComment: SetCommentType = (form) => {
		return API.wallet.updateSetting({ hash_id: form.hash_id, comment: form.comment }).then((resp) => {
			if (resp.status === "success") {
				getWallets();
			}
			return resp;
		});
	};

	const openDeleteWalletModal = (hash_id: WalletTypeType[`hash_id`]) => {
		setDeleteModalData({
			open: true,
			hash_id,
		});
	};

	const closeDeleteWalletModal = () => {
		setDeleteModalData({
			open: false,
			hash_id: `0`,
		});
	};

	const openRecoverWalletModal = (hash_id: WalletTypeType[`hash_id`]) => {
		setRecoverModalData({
			open: true,
			hash_id,
		});
	};

	const closeRecoverWalletModal = () => {
		setRecoverModalData({
			open: false,
			hash_id: `0`,
		});
	};

	const openMassRecoverWalletModal = () => {
		setMassRecoverModalData(true);
	};

	const closeMassRecoverWalletModal = () => {
		setMassRecoverModalData(false);
	};
	const openMassDeleteWalletModal = () => {
		setMassDeleteModalData(true);
	};

	const closeMassDeleteWalletModal = () => {
		setMassDeleteModalData(false);
	};

	const openCommentModal = (hash_id: WalletTypeType[`hash_id`], comment: string | null) => {
		setCommentModalData({
			open: true,
			hash_id,
			comment,
		});
	};

	const closeCommentModal = () => {
		setCommentModalData({
			open: false,
			hash_id: `0`,
			comment: null,
		});
	};

	useEffect(() => {
		getWallets();
	}, [filter]);

	const value = {
		getWallets,
		wallets,
		getWallets,
		isLoading,
		selectedHashIDs,
		setSelectedHashIDs,
		openRecoverWalletModal,
		openMassRecoverWalletModal,
		openDeleteWalletModal,
		openMassDeleteWalletModal,
		openCommentModal,
		filter,
		setFilter,
		paginationInfo,
	};

	return (
		<ArchivedWalletContext.Provider value={value}>
			{props.children}
			<Modal title={t("Deleting wallet")} isOpen={deleteModalData.open} onClose={closeDeleteWalletModal}>
				<DeleteWalletModal
					closeModal={closeDeleteWalletModal}
					hash_id={deleteModalData.hash_id}
					actions={{
						deleteWallet,
					}}
				/>
			</Modal>
			<Modal title={t("Deleting wallets")} isOpen={massDeleteModalData} onClose={closeMassDeleteWalletModal}>
				<MassDeleteWalletModal
					closeModal={closeMassDeleteWalletModal}
					isOpen={massDeleteModalData}
					ids={selectedHashIDs}
					actions={{
						clearSelectedIds: () => setSelectedHashIDs([]),
						deleteWallet,
						getWallets,
					}}
				/>
			</Modal>
			<Modal title={t("Wallet recovery")} isOpen={recoverModalData.open} onClose={closeRecoverWalletModal}>
				<RecoverWalletModal
					closeModal={closeRecoverWalletModal}
					hash_id={recoverModalData.hash_id}
					actions={{
						recoverWallet,
					}}
				/>
			</Modal>
			<Modal title={t("Wallets recovery")} isOpen={massRecoverModalData} onClose={closeMassRecoverWalletModal}>
				<MassRecoverWalletModal
					closeModal={closeMassRecoverWalletModal}
					isOpen={massRecoverModalData}
					ids={selectedHashIDs}
					actions={{
						clearSelectedIds: () => setSelectedHashIDs([]),
						recoverWallet,
						getWallets,
					}}
				/>
			</Modal>
			<Modal title={t("Comment")} isOpen={commentModalData.open} onClose={closeCommentModal}>
				<CommentModal
					closeModal={closeCommentModal}
					actions={{
						setComment,
					}}
					comment={commentModalData.comment}
					hashId={commentModalData.hash_id}
				/>
			</Modal>
		</ArchivedWalletContext.Provider>
	);
};

export default ArchivedWalletContextProvider;

type ArchivedWalletContextProviderType = {
	readonly children: ReactElement;
};

type ArchivedWalletContextType = {
	readonly wallets: readonly WalletListItem[];
	readonly getWallets: () => void;
	readonly isLoading: boolean;
	readonly selectedHashIDs: readonly string[];
	readonly setSelectedHashIDs: React.Dispatch<React.SetStateAction<readonly string[]>>;
	readonly filter: WalletsFilterForm;
	readonly setFilter: React.Dispatch<React.SetStateAction<WalletsFilterForm>>;
	readonly openRecoverWalletModal: (hash_id: WalletTypeType[`hash_id`]) => void;
	readonly openDeleteWalletModal: (hash_id: WalletTypeType[`hash_id`]) => void;
	readonly openMassRecoverWalletModal: () => void;
	readonly openMassDeleteWalletModal: () => void;
	readonly openCommentModal: (hash_id: WalletTypeType[`hash_id`], comment: string | null) => void;
};

type ModalData = {
	readonly open: boolean;
};

type WalletModalData = ModalData & {
	readonly hash_id: WalletTypeType[`hash_id`];
};

type CommentModalData = ModalData & {
	readonly hash_id: WalletTypeType[`hash_id`];
	readonly comment: string | null;
};

export type SetCommentType = (form: {
	readonly hash_id: WalletTypeType[`hash_id`];
	readonly comment: WalletTypeType[`comment`];
}) => Promise<StandardResponse<unknown>>;
