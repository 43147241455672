// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WorkingWalletContext__createNewModal-lcB{width:560px;max-width:none !important;padding-right:0 !important}.WorkingWalletContext__createNewModal-lcB [class^=Modal__content],.WorkingWalletContext__createNewModal-lcB [class^=Modal__header]{padding-right:32px}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/contexts/WorkingWalletContext/Wallets.module.scss"],"names":[],"mappings":"AAAA,0CACC,WAAA,CACA,yBAAA,CACA,0BAAA,CAEA,mIACC,kBAAA","sourcesContent":[".createNewModal {\n\twidth: 560px;\n\tmax-width: none!important;\n\tpadding-right: 0!important;\n\n\t[class^=\"Modal__content\"], [class^=\"Modal__header\"] {\n\t\tpadding-right: 32px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createNewModal": `WorkingWalletContext__createNewModal-lcB`
};
export default ___CSS_LOADER_EXPORT___;
