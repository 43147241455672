import { PayKitForm } from "@paykassma/pay-kit";
import { useTranslation } from "pay-kit";
import { useContext } from "react";
import { fromLocaleDate, notLaterThanToday } from "utils/date";

import { CommissionReportReqType } from "/api/reportsGroup";

import { ActionsContext } from "../../../../../ActionsProvider";
import styles from "./CommissionReport.module.scss";

const CommissionReport = () => {
	const { createCommissionReportAPI } = useContext(ActionsContext);

	const { t } = useTranslation();

	const FORM_SCHEMA = [
		{
			type: "BaseSettingsSection",
			elements: [
				{
					name: "date",
					label: t("Period"),
					type: "DateRangePicker",
					isRequired: true,
					fromPlaceholder: t("From"),
					toPlaceholder: t("To"),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					withTime: true,
					validation: (value, { date }) => (date === undefined ? t("Choose period") : undefined),
				},
			],
		},
		{
			type: "BottomSection",
			elements: [
				{
					name: "file_format",
					label: t("Report format"),
					type: "Switcher",
					options: [
						{ value: "xlsx", label: "xlsx" },
						{ value: "csv", label: "csv" },
					],
					className: styles.formatSwitcher,
				},
				{
					type: "SubmitButton",
					name: "submitButton",
					label: t("Create"),
					isLoading: createCommissionReportAPI.isLoading,
					onSubmit: (formState: FormStateType) => createCommissionReportAPI.create(prepareFormData(formState)),
				},
			],
		},
	];

	const customElements = {
		BaseSettingsSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.baseSettings}>{children}</div>} />
		),
		BottomSection: (props) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.actions}>{children}</div>} />
		),
	};

	return (
		<div className={styles.form}>
			<PayKitForm.Builder<FormStateType>
				schema={FORM_SCHEMA}
				initialState={{ file_format: "xlsx" }}
				customElements={customElements}
			/>
		</div>
	);
};

export default CommissionReport;

type FormStateType = {
	readonly date?: {
		readonly from?: string;
		readonly to?: string;
	};
	readonly file_format: "xlsx" | "csv";
};

type prepareFormDataType = (rawFormData: FormStateType) => CommissionReportReqType;

const prepareFormData: prepareFormDataType = ({ date, file_format }) => ({
	file_format,
	date_from: fromLocaleDate(date?.from) || undefined,
	date_to: fromLocaleDate(date?.to) || undefined,
});
