import API from "api";
import { UpdatePrivateSettingsType } from "api/settingsGroup";
import { GetProxySettingsType } from "api/walletTypesGroup";
import { useCallback, useState } from "react";
import { errorsMap } from "utils/enums";

const useGetProxySettings = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<GetProxySettingsType | null>(null);

	const load: LoadType = useCallback((walletType) => {
		setError(null);
		setIsLoading(true);
		setData(null);

		API.walletType
			.getProxySettings(walletType)
			.then((resp) => {
				if (resp.status === "ok") {
					setData(resp);
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return {
		load,
		data,
		isLoading,
		error,
	};
};

export default useGetProxySettings;

type LoadType = (walletType: string) => void;
