import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import { FC, ReactElement } from "react";

import styles from "./sortablePSElements.module.scss";

const SortablePSElements: FC<SortablePSElementsType> = ({
	children,
	currencyElement,
	save,
	reset,
	hideActionsGroup,
	hideHeader,
	className,
	disableReset,
}) => {
	const { t } = useTranslation();
	return (
		<div className={styles.SortablePSElements}>
			{currencyElement}
			{!hideHeader && <div className={[styles.viewHeader, className].join(" ")}>{t(`Display order`)}</div>}

			{children}
			{!hideActionsGroup && (
				<div className={styles.actionsGroup}>
					<Button variant="secondary" data-test-id="nB5RFnxjCwBlgJYABnhGU" onClick={reset} disabled={disableReset}>
						{t("Reset")}
					</Button>
					<Button data-test-id="nB5RFnxjCwBlgJYABnhGU" onClick={save}>
						{t("Save")}
					</Button>
				</div>
			)}
		</div>
	);
};

export default SortablePSElements;

type SortablePSElementsType = {
	readonly children: ReactElement;
	readonly currencyElement: ReactElement;
	readonly hideActionsGroup?: boolean;
	readonly hideHeader?: boolean;
	readonly reset: () => void;
	readonly save: () => void;
	readonly className?: string;
	readonly disableReset?: boolean;
};
