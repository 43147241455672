import { useTranslation } from "pay-kit";
import RoutedTabs, { RoutedTab } from "components/RoutedTabs";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import PageTitle from "src/components/PageTitle";

import styles from "./styles.module.scss";

export default function ReportsPage() {
	const { hasRole } = useContext(AuthContext);
	const {t} = useTranslation();
	const tabs: RoutedTab[] = [
		{
			label: t("Report list"),
			value: "/reports",
		},
		{
			label: t("Tasks"),
			value: "/reports/autoreports",
			visible: hasRole(Roles.MANAGE_AUTO_GENERATING_REPORTS),
		},
	];

	return (
		<div className={styles.panel}>
			<PageTitle title={t("Reports")} />
			<RoutedTabs tabs={tabs} />
			<Outlet />
		</div>
	);
}
