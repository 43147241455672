import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import { FC } from "react";

import styles from "./sortableElements.module.scss";


const SortableElements: FC<SortableElementsType> = ({ type, activeId, ...rest }) => {
	const sortable = useSortable({ id: type });
	const {
		attributes,
		listeners,
		isDragging,
		setNodeRef,
		transform,
		transition
	} = sortable;

	const style = {
		transform: CSS.Transform.toString(transform),
		transition
	};

	const draggableElementStyle = activeId === type ? [styles.draggableElement, styles.activeElement].join(" ") : styles.draggableElement;

	return (
		<div
			ref={setNodeRef as never}
			{...attributes}
			{...listeners}
			{...rest}
			style={style}
			className={draggableElementStyle}
		>
			<PaymentSystemLogo id={type} classNames={{ wrapper: styles.logoWrapper }} />
		</div>
	);
};

export default SortableElements;

type SortableElementsType = {
	readonly type: string
	readonly index: number
	readonly activeId: string
}