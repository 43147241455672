// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SortablePSElements__SortablePSElements-McS .SortablePSElements__viewHeader-rit{margin-top:28px;color:#21272a;font-size:18px;font-weight:500}.SortablePSElements__SortablePSElements-McS .SortablePSElements__actionsGroup-CkP{display:flex;justify-content:flex-end;gap:10px;margin-top:34px}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/Plugin/components/PSDisplayOrder/components/SortablePSElements/sortablePSElements.module.scss"],"names":[],"mappings":"AAEC,gFACC,eAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CAGD,kFACC,YAAA,CACA,wBAAA,CACA,QAAA,CAEA,eAAA","sourcesContent":[".SortablePSElements {\n\n\t.viewHeader {\n\t\tmargin-top: 28px;\n\t\tcolor: #21272A;\n\t\tfont-size: 18px;\n\t\tfont-weight: 500;\n\t}\n\n\t.actionsGroup {\n\t\tdisplay: flex;\n\t\tjustify-content: flex-end;\n\t\tgap: 10px;\n\n\t\tmargin-top: 34px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SortablePSElements": `SortablePSElements__SortablePSElements-McS`,
	"viewHeader": `SortablePSElements__viewHeader-rit`,
	"actionsGroup": `SortablePSElements__actionsGroup-CkP`
};
export default ___CSS_LOADER_EXPORT___;
