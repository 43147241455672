import {
	closestCenter,
	DndContext,
	DragEndEvent,
	DragStartEvent,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { Select } from "@paykassma/pay-kit";
import Grid from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/Grid";
import useGetPaymentOfCurrencies from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/NewDisplayOrderAddition/hooks/useGetPaymentOfCurrencies";
import SortableElements from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/SortableElements";
import SortablePSElements from "modules/AppSettings/Plugin/components/PSDisplayOrder/components/SortablePSElements";
import { ScrollBox, useTranslation } from "pay-kit";
import { useEffect, useState } from "react";

import styles from "./newDisplayOrderAddition.module.scss";

const NewDisplayOrderAddition = () => {
	const [selectedValue, setSelectedValue] = useState(``);
	const paymentOfCurrenciesAPI = useGetPaymentOfCurrencies();
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
	const [resetItems, setResetItems] = useState<readonly string[] | undefined>(undefined);
	const [items, setItems] = useState<readonly string[] | undefined>(undefined);
	const [activeId, setActiveId] = useState<string | null>(null);
	const { t } = useTranslation();

	useEffect(() => {
		paymentOfCurrenciesAPI.load();
	}, [paymentOfCurrenciesAPI.load]);

	const onDragStart = (event: DragStartEvent) => {
		setActiveId(event.active?.id as string);
	};

	const onDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (active?.id !== over?.id) {
			setItems((items) => {
				const oldIndex = items?.indexOf(active.id as string);
				const newIndex = items?.indexOf(over?.id as string);
				const itemExists = items && oldIndex !== undefined && newIndex !== undefined;

				return itemExists ? arrayMove([...items], oldIndex, newIndex) : items;
			});
		}
		setActiveId(null);
	};

	function onDragCancel() {
		setActiveId(null);
	}

	function onChange<T>(value: T) {
		setSelectedValue(value as string);
	}

	const options =
		paymentOfCurrenciesAPI.list?.map((currency) => ({ label: currency.code, value: currency.code })) || [];
	const currencyPayments = paymentOfCurrenciesAPI.list?.find((currency) => currency.code === selectedValue);

	useEffect(() => {
		if (currencyPayments?.payments.length) {
			const payments = currencyPayments?.payments.map((payment) => payment.type);
			setItems(payments);
			setResetItems(payments);
		}
	}, [currencyPayments?.payments.length]);

	const save = () => {};
	const reset = () => {
		setItems(resetItems);
	};

	const selectCurrency = (
		<div className={styles.chooseCurrency}>
			<div className={styles.currencyHeader}>{t(`Choose a currency`)}</div>

			<Select
				name="currency-list"
				options={options}
				placeholder={t(`Choose`)}
				onChange={onChange}
				value={selectedValue}
				isLoading={paymentOfCurrenciesAPI.isLoading}
				className={styles.select}
			/>
		</div>
	);

	const currencyExists = !!currencyPayments?.code;

	return (
		<div className={styles.newDisplayAddition}>
			<SortablePSElements
				save={save}
				reset={reset}
				currencyElement={selectCurrency}
				hideActionsGroup={!currencyExists}
				hideHeader={!currencyExists}
				className={styles.header}
			>
				<>
					{currencyExists && (
						<>
							<DndContext
								sensors={sensors}
								collisionDetection={closestCenter}
								onDragStart={onDragStart}
								onDragEnd={onDragEnd}
								onDragCancel={onDragCancel}
							>
								<SortableContext items={[...(items || [])]} strategy={rectSortingStrategy}>
									<ScrollBox className={styles.scrollBox}>
										<Grid>
											{items?.map((type, index) => (
												<SortableElements key={type} type={type} index={index} activeId={activeId as string} />
											))}
										</Grid>
									</ScrollBox>
								</SortableContext>
							</DndContext>
						</>
					)}
				</>
			</SortablePSElements>
		</div>
	);
};

export default NewDisplayOrderAddition;
