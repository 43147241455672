import { WalletListItem } from "api/walletGroup";
import { useWalletTypesContext, WalletTypesContext } from "contexts/WalletTypesContext";
import { WalletsAmountSideBarDataType } from "modules/Proxies/contexts/ProxyContext";
import { useCallback, useState } from "react";
import API from "api";

const handleWalletCode = (walletTypes, type) => {
	const	finded = walletTypes?.find((walletType) => walletType.code === type);

	return finded?.name || type;
};

export const useWalletTypesList = () => {
	const [list, setList] = useState<readonly WalletType[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const walletTypesContextAPI = useWalletTypesContext();

	const handle: WalletTypesListHandlerType = useCallback(
		({ data }) => {
			const fetchData = async () => {
				setIsLoading(true);

				if (data) {
					const promises = data.IDs.map((id) =>
						API.wallet.getWallet(id).then((res) => {
							console.log({ res });
							if (res.status === "success") {
								return {
									id: res.data.identifier,
									code: handleWalletCode(walletTypesContextAPI.walletTypes, res.data.wallet_type),
									isActive: res.data.is_active,
									proxies: res.data.proxies,
								};
							}
							return null;
						})
					);

					const results = await Promise.all(promises);
					const list = results.filter((item) => item !== null);

					setList(list);
				}

				setIsLoading(false);
			};

			fetchData();
		},

		[walletTypesContextAPI.walletTypes.length]
	);

	return {
		handle,
		list,
		isLoading,
	};
};

type WalletType = { readonly id: string; readonly code: string; readonly isActive: boolean; proxies: readonly Proxy[] };

type handleWalletCode = (walletTypes: WalletTypesContext[`walletTypes`]) => (code: string) => string;

type WalletTypesListHandlerType = (param: {
	readonly data: WalletsAmountSideBarDataType[`walletsData`];
	readonly WalletList: readonly WalletListItem[] | undefined;
}) => void;
