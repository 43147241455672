/* eslint-disable promise/catch-or-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import API from "api";
import { Proxy } from "api/proxyGroup";
import { StandardResponse } from "api/types";
import { WalletItem } from "api/walletGroup";
import { Requisite } from "api/walletTypesGroup";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import ChangeWalletModal from "modules/Wallets/components/modals/ChangeWalletModal";
import { useTranslation } from "pay-kit";
import { useContext, useEffect, useState } from "react";
import { screenPassword } from "utils/screening";
import { ModalProps } from "utils/types";

type UpdateWalletModalProps = ModalProps & {
	readonly isOpen: boolean;
	readonly actions: {
		readonly updateWallet: (form: any) => Promise<StandardResponse<void>>;
	};
	readonly walletId: string;
};

export const EditWalletModal = ({ closeModal, isOpen, actions, walletId }: UpdateWalletModalProps) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(true);

	const [initForm, setInitForm] = useState<Partial<any>>({});

	const [respErrors, setErrors] = useState<any | null>(null);

	const [requisites, setRequisites] = useState<readonly Requisite[]>([]);

	const clearExternalErrors = () => {
		setErrors(null);
	};

	useEffect(() => {
		if (!isOpen) {
			clearExternalErrors();
		}
	}, []);

	const fetchRequisites = (wallet_type: string) => {
		API.walletType.getManualPSRequisites(wallet_type).then((resp) => {
			if (resp.status === "success") {
				setRequisites(resp.data.items);
			}
		});
	};

	const { walletTypes } = useContext(WalletTypesContext);

	useEffect(() => {
		API.wallet
			.getItem(walletId)
			.then((res: any) => {
				if (res?.status === "ok") {
					const data = res?.wallet;
					for (const key of Object.keys(data)) {
						if (data[key] === null) {
							delete data[key];
						}
					}
					const wallet_type = data.type;
					const isManual = walletTypes?.find((wt: any) => wt.code === wallet_type)?.payment_type === `MANUAL`;
					if (isManual) {
						fetchRequisites(wallet_type);
					}
					const currency_code = data.currency.code;
					const proxies = data.proxies.map(({ uuid }: any) => ({ uuid }));
					const cookie: string | undefined = data.cookie ? JSON.stringify(data.cookie) : undefined;

					if (data.password) {
						data.password = screenPassword(data.password);
					}

					if (data.email_password) {
						data.email_password = screenPassword(data.email_password);
					}

					if (data.upi_addresses) {
						data.upi_addresses = data.upi_addresses.map((upi_address: string) => ({ upi_address }));
					}

					const initForm = {
						...data.wallet_settings,
						...data,
						currency_code,
						night_parser: false,
						site_parser: true,
						wallet_type,
						proxies,
						cookie,
					};
					setInitForm(initForm);
					return;
				} else {
					throw new Error("Unexpected response");
				}
			})
			.catch((err) => {
				window.pushAlert({ description: t("Error while wallet data fetching"), type: "error" });
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const onSuccess = () => {
		window.pushAlert({
			title: t("Success"),
			description: t("Wallet {{walletId}} changed", { walletId }),
			type: "success",
		});
	};

	const onSubmit = (form: Partial<WalletItem>) => {
		const proxies = (form.proxies || []).map(({ uuid }: Partial<Proxy>) => uuid);

		// if (form.account_number === "") {
		// 	form.account_number = null;
		// }

		let upi_addresses = (form.upi_addresses || []).map(({ upi_address }: Partial<WalletItem>) => upi_address);
		if (upi_addresses.length === 0) {
			upi_addresses = undefined;
		}
		let cookie = JSON.parse(form.cookie || "{}");
		if (Object.keys(cookie).length === 0) {
			cookie = undefined;
		}
		return actions.updateWallet({ ...form, proxies, cookie, upi_addresses }).then((resp) => {
			if (resp.status === "error") {
				const errors = resp.errors;
				setErrors(errors);
			}

			return resp;
		});
	};

	return (
		<ChangeWalletModal
			isCreating={false}
			loading={loading}
			isOpen={isOpen}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			initForm={initForm}
			closeModal={closeModal}
			actions={actions}
			externalErrors={respErrors}
			clearExternalErrors={clearExternalErrors}
			fetchRequisites={fetchRequisites}
			requisites={requisites}
		/>
	);
};

export default EditWalletModal;
