import { CurrencyListWithCustomSorting } from "api/settingsGroup";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { usePSDisplayOrderContext } from "modules/AppSettings/Plugin/components/PSDisplayOrder/context";
import Loader from "modules/AppSettings/Plugin/components/PSHints/components/Loader";
import { useTranslation } from "pay-kit";
import { Button } from "pay-kit";
import { FC, useContext, useState } from "react";

import styles from "./pSDisplayOrder.module.scss";

const PSDisplayOrder: FC<PSDisplayOrderType> = ({ list, isPluginSettingsLoading }) => {
	const { t } = useTranslation();
	const [activePsCode, setActivePSCode] = useState(``);
	const psDisplayOrderContextAPI = usePSDisplayOrderContext();
	const walletTypesAPI = useContext(WalletTypesContext);

	const onPSClick: OnPSClickHandler = (ps) => () => {
		setActivePSCode(ps.code);
		psDisplayOrderContextAPI?.openDisplayOrderModal(true, ps);
	};

	const onAdd = () => {
		psDisplayOrderContextAPI?.openNewDisplayOrderAdditionModal();
	};

	const renderPsList = () =>
		list?.map((ps) => {
			const psStyle = ps.code === activePsCode ? [styles.ps, styles.activePs].join(" ") : styles.ps;
			return (
				<div data-test-id="IYbcG_L44EQDQA-gz5PLS" key={ps.code} onClick={onPSClick(ps)} className={psStyle}>
					{ps.code}
				</div>
			);
		});

	return (
		<div className={styles.psDisplayOrder}>
			<Button data-test-id="AfhhG3CTouoIIjqj59vrI" classname={styles.addPSOrderButton} onClick={onAdd}>
				+{t("Add")}
			</Button>

			<Loader loading={isPluginSettingsLoading || walletTypesAPI.isLoading} className={styles.loading}>
				<div className={styles.customDisplayOrderGrid}>{renderPsList()}</div>
			</Loader>
		</div>
	);
};

export default PSDisplayOrder;

type PSDisplayOrderType = {
	readonly list?: readonly CurrencyListWithCustomSorting[];
	readonly isPluginSettingsLoading: boolean;
};

type OnPSClickHandler = (ps: CurrencyListWithCustomSorting) => () => void;
