import API from "api";
import { OldResponse, PaginationData, StandardResponse } from "api/types";
import { UpdateSettingData, WalletListItem, WalletsFilterForm, WalletTypeType } from "api/walletGroup";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import CommentModal from "modules/Wallets/components/modals/CommentModal";
import CreateWalletModal from "modules/Wallets/components/modals/CreateWalletModal";
import EditWalletModal from "modules/Wallets/components/modals/EditWalletModal";
import { ActivateWalletModal } from "modules/Wallets/DisabledWallets/components/modals/ActivateWalletModal";
import { ArchiveWalletModal } from "modules/Wallets/DisabledWallets/components/modals/ArchiveWalletModal";
import { MassActivateWalletModal } from "modules/Wallets/DisabledWallets/components/modals/MassActivateWalletModal";
import { MassArchiveWalletModal } from "modules/Wallets/DisabledWallets/components/modals/MassArchiveWalletModal";
import { DisableWalletModal } from "modules/Wallets/WorkingWallets/components/modals/DisableWalletModal";
import { MassDisableWalletModal } from "modules/Wallets/WorkingWallets/components/modals/MassDisableWalletModal";
import { Modal, useTranslation } from "pay-kit";
import React, { useContext, useEffect, useState } from "react";

import styles from "./Wallets.module.scss";

type ModalData = {
	readonly open: boolean;
};

type WalletModalData = ModalData & {
	readonly hash_id: WalletTypeType["hash_id"];
};

type CreateModalData = ModalData & {
	readonly disabled: boolean;
};

type CommentModalData = ModalData & {
	readonly hash_id: WalletTypeType["hash_id"];
	readonly comment: string | null;
};

export const WorkingWalletContext = React.createContext<WorkingWalletContextType>({} as WorkingWalletContextType);
WorkingWalletContext.displayName = "WorkingWalletContext";

type WalletContextProps = {
	readonly children: React.ReactNode;
	readonly type: "working" | "disabled";
	readonly initFetch?: boolean;
};

export default function WorkingWalletContextProvider({ children, type, initFetch = true }: WalletContextProps) {
	const [wallets, setWallets] = useState<readonly WalletListItem[]>([]);
	const [selectedHashIDs, setSelectedHashIDs] = useState<readonly (string | number)[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<WalletsFilterForm>({});
	const [paginationInfo, setPaginationInfo] = useState<PaginationData | null>(null);
	const { hasRole, user } = useContext(AuthContext);
	const { t } = useTranslation();

	const [disableModalData, setDisableModalData] = useState<WalletModalData>({
		open: false,
		hash_id: `0`,
	});

	const [archiveModalData, setArchiveModalData] = useState<WalletModalData>({
		open: false,
		hash_id: `0`,
	});

	const [activateModalData, setActivateModalData] = useState<WalletModalData>({
		open: false,
		hash_id: `0`,
	});

	const [massDisableModalData, setMassDisableModalData] = useState<boolean>(false);

	const [massArchiveModalData, setMassArchiveModalData] = useState<boolean>(false);

	const [massActivateModalData, setMassActivateModalData] = useState<boolean>(false);

	const [createModalData, setCreateModalData] = useState<CreateModalData>({
		open: false,
		disabled: false,
	});

	const [editModalData, setEditModalData] = useState<WalletModalData>({
		open: false,
		hash_id: `0`,
	});

	const [commentModalData, setCommentModalData] = useState<CommentModalData>({
		open: false,
		hash_id: `0`,
		comment: null,
	});

	const walletsFilters = {
		removed: 0,
		is_active: type === "working" ? 1 : 0,
	};

	const getWallets = (params: WalletsFilterForm = {}) => {
		if (!hasRole(Roles.WALLET_LIST)) {
			return;
		}

		setLoading(true);

		const handledFilter = {
			...walletsFilters,
			removed: params.removed ? 1 : 0 || walletsFilters.removed ? 1 : 0,
			is_active: params.is_active ? 1 : 0 || walletsFilters.is_active ? 1 : 0,
			...params,
			...filter,
		};

		return API.wallet
			.getList(handledFilter)
			.then((resp) => {
				if (resp.status === `success`) {
					setWallets(resp.data);
					setPaginationInfo(resp.paginate);
				}
				return resp;
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateSetting = (data: UpdateSettingData): Promise<StandardResponse<unknown>> => API.wallet.updateSetting(data);

	const disableWallet = (hash_id: WalletListItem[`hash_id`], fetchWallets = true): Promise<OldResponse<void>> => {
		return API.wallet.disableWallet(hash_id).then(async (resp) => {
			if (resp.status === "ok" && fetchWallets) {
				await getWallets();
			}

			return resp;
		});
	};

	const archiveWallet = (hash_id: WalletListItem[`hash_id`], fetchWallets = true): Promise<OldResponse<void>> => {
		return API.wallet.archiveWallet(hash_id).then(async (resp) => {
			if (resp.status === "ok" && fetchWallets) {
				await getWallets();
			}

			return resp;
		});
	};

	const activateWallet = (hash_id: WalletListItem[`hash_id`], fetchWallets = true): Promise<OldResponse<void>> => {
		return API.wallet.activateWallet(hash_id).then(async (resp) => {
			if (resp.status === "ok" && fetchWallets) {
				await getWallets();
			}

			return resp;
		});
	};

	const createWallet = (form: any): Promise<StandardResponse<unknown>> => {
		const data = { ...form };
		data.is_active = form.status === "working";
		if (data.upi_addresses) {
			data.upi_addresses = data.upi_addresses.map((upi: any) => upi.upi_address);
		}
		return API.wallet.create(data).then(async (resp) => {
			if (resp.status === "success") {
				getWallets();
			}

			return resp;
		});
	};

	const updateWallet = (form: any): Promise<StandardResponse<unknown>> => {
		const data = { ...form };
		if (data.priority) {
			data.priority = +form.priority;
		}
		return API.wallet.updateWallet(editModalData.hash_id, data).then((resp) => {
			if (resp.status === "success") {
				getWallets();
			}

			return resp;
		});
	};

	const setComment = (form: Omit<CommentModalData, "open">) => {
		return API.wallet.updateSetting({ hash_id: commentModalData.hash_id, comment: form.comment }).then((resp) => {
			if (resp.status === "success") {
				getWallets();
			}

			return resp;
		});
	};

	const openDisableWalletModal = (hash_id: WalletTypeType[`hash_id`]) => {
		setDisableModalData({
			open: true,
			hash_id,
		});
	};

	const closeDisableWalletModal = () => {
		setDisableModalData({
			open: false,
			hash_id: `0`,
		});
	};

	const openArchiveWalletModal = (hash_id: WalletTypeType[`hash_id`]) => {
		setArchiveModalData({
			open: true,
			hash_id,
		});
	};

	const closeArchiveWalletModal = () => {
		setArchiveModalData({
			open: false,
			hash_id: `0`,
		});
	};

	const openActivateWalletModal = (hash_id: WalletTypeType[`hash_id`]) => {
		setActivateModalData({
			open: true,
			hash_id,
		});
	};

	const closeActivateWalletModal = () => {
		setActivateModalData({
			open: false,
			hash_id: `0`,
		});
	};

	const openMassDisableWalletModal = () => {
		setMassDisableModalData(true);
	};

	const closeMassDisableWalletModal = () => {
		setMassDisableModalData(false);
	};

	const openMassArchiveWalletModal = () => {
		setMassArchiveModalData(true);
	};

	const closeMassArchiveWalletModal = () => {
		setMassArchiveModalData(false);
	};

	const openMassActivateWalletModal = () => {
		setMassActivateModalData(true);
	};

	const closeMassActivateWalletModal = () => {
		setMassActivateModalData(false);
	};

	const openCreateWalletModal = (disabled: boolean) => {
		setCreateModalData({
			open: true,
			disabled,
		});
	};

	const closeCreateWalletModal = () => {
		setCreateModalData({
			open: false,
			disabled: false,
		});
	};

	const openEditWalletModal = (hash_id: string) => {
		setEditModalData({
			open: true,
			hash_id,
		});
	};

	const closeEditWalletModal = () => {
		setEditModalData({
			open: false,
			hash_id: `0`,
		});
	};

	const openCommentModal = (hash_id: WalletTypeType[`hash_id`], comment: string | null) => {
		setCommentModalData({
			open: true,
			hash_id,
			comment,
		});
	};

	const closeCommentModal = () => {
		setCommentModalData({
			open: false,
			hash_id: `0`,
			comment: null,
		});
	};

	useEffect(() => {
		if (initFetch) {
			getWallets();
		}
	}, [user.name]);

	const value = {
		wallets,
		isLoading,
		updateSetting,
		openDisableWalletModal,
		openMassDisableWalletModal,
		openCreateWalletModal,
		openEditWalletModal,
		openArchiveWalletModal,
		openActivateWalletModal,
		openMassArchiveWalletModal,
		openMassActivateWalletModal,
		openCommentModal,
		selectedHashIDs,
		setSelectedHashIDs,
		getWallets,
		paginationInfo,
		filter,
		setFilter,
		getWallets,
	};

	return (
		<WorkingWalletContext.Provider value={value}>
			{children}
			<Modal title={t("Wallet disabling")} isOpen={disableModalData.open} onClose={closeDisableWalletModal}>
				<DisableWalletModal
					closeModal={closeDisableWalletModal}
					hash_id={disableModalData.hash_id}
					actions={{
						disableWallet,
					}}
				/>
			</Modal>
			<Modal title={t("Wallet archiving")} isOpen={archiveModalData.open} onClose={closeArchiveWalletModal}>
				<ArchiveWalletModal
					closeModal={closeArchiveWalletModal}
					hash_id={archiveModalData.hash_id}
					actions={{
						archiveWallet,
					}}
				/>
			</Modal>
			<Modal title={t("Wallets archiving")} isOpen={massArchiveModalData} onClose={closeMassArchiveWalletModal}>
				<MassArchiveWalletModal
					closeModal={closeMassArchiveWalletModal}
					isOpen={massArchiveModalData}
					ids={selectedHashIDs as readonly WalletListItem[`id`][]}
					actions={{
						clearSelectedIds: () => setSelectedHashIDs([]),
						archiveWallet,
						getWallets,
					}}
				/>
			</Modal>
			<Modal title={t("Wallet activation")} isOpen={activateModalData.open} onClose={closeActivateWalletModal}>
				<ActivateWalletModal
					closeModal={closeActivateWalletModal}
					hash_id={activateModalData.hash_id}
					actions={{
						activateWallet,
					}}
				/>
			</Modal>
			<Modal title={t("Wallets activation")} isOpen={massActivateModalData} onClose={closeMassActivateWalletModal}>
				<MassActivateWalletModal
					closeModal={closeMassActivateWalletModal}
					isOpen={massActivateModalData}
					ids={selectedHashIDs as readonly string[]}
					actions={{
						clearSelectedIds: () => setSelectedHashIDs([]),
						activateWallet,
						getWallets,
					}}
				/>
			</Modal>
			<Modal title={t("Wallets disabling")} isOpen={massDisableModalData} onClose={closeMassDisableWalletModal}>
				<MassDisableWalletModal
					closeModal={closeMassDisableWalletModal}
					isOpen={massDisableModalData}
					ids={selectedHashIDs as readonly WalletListItem[`id`][]}
					actions={{
						clearSelectedIds: () => setSelectedHashIDs([]),
						disableWallet,
						getWallets,
					}}
				/>
			</Modal>
			<Modal title={t("Wallet creating")} isOpen={createModalData.open} onClose={closeCreateWalletModal} className={styles.createNewModal}>
				<CreateWalletModal
					closeModal={closeCreateWalletModal}
					isOpen={createModalData.open}
					disabled={createModalData.disabled}
					actions={{
						createWallet,
					}}
				/>
			</Modal>
			<Modal title={t("Wallet updating")} isOpen={editModalData.open} onClose={closeEditWalletModal} className={styles.createNewModal}>
				<EditWalletModal
					closeModal={closeEditWalletModal}
					isOpen={editModalData.open}
					actions={{
						updateWallet,
					}}
					walletId={editModalData.hash_id}
				/>
			</Modal>
			<Modal title={t("Comment")} isOpen={commentModalData.open} onClose={closeCommentModal}>
				<CommentModal
					closeModal={closeCommentModal}
					actions={{
						setComment,
					}}
					comment={commentModalData.comment}
					hashId={commentModalData.hash_id}
				/>
			</Modal>
		</WorkingWalletContext.Provider>
	);
}

export const useWorkingWalletContext = () => useContext(WorkingWalletContext);

type WorkingWalletContextType = {
	readonly wallets: readonly WalletListItem[];
	readonly getWallets: () => void;
	readonly isLoading: boolean;
	readonly updateSetting: (data: UpdateSettingData) => Promise<StandardResponse<void>>;
	readonly openArchiveWalletModal: (hash_id: WalletTypeType[`hash_id`]) => void;
	readonly openMassActivateWalletModal: () => void;
	readonly openMassArchiveWalletModal: () => void;
	readonly openActivateWalletModal: (hash_id: WalletTypeType[`hash_id`]) => void;
	readonly openDisableWalletModal: (id: WalletTypeType[`hash_id`]) => void;
	readonly openMassDisableWalletModal: () => void;
	readonly openCreateWalletModal: (disabled: boolean) => void;
	readonly openEditWalletModal: (walletId: WalletTypeType[`hash_id`]) => void;
	readonly openCommentModal: (id: WalletTypeType[`hash_id`], comment: string | null) => void;
	readonly selectedHashIDs: readonly (string | number)[];
	readonly setSelectedHashIDs: React.Dispatch<React.SetStateAction<readonly (string | number)[]>>;
	readonly paginationInfo: PaginationData | null;
	readonly filter: WalletsFilterForm;
	readonly setFilter: React.Dispatch<React.SetStateAction<WalletsFilterForm>>;
};
