// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SavingDisplayOrder__savingDisplayOrder-mwz .SavingDisplayOrder__infoHeader-APs{color:#21272a;font-size:14px;font-weight:500}.SavingDisplayOrder__savingDisplayOrder-mwz .SavingDisplayOrder__questioningHeader-Dlx{margin-top:16px;color:#21272a;font-size:14px;font-weight:400}.SavingDisplayOrder__savingDisplayOrder-mwz .SavingDisplayOrder__actionsGroup-8Ej{display:flex;justify-content:end;gap:10px;margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/Plugin/components/PSDisplayOrder/components/SavingDisplayOrder/savingDisplayOrder.module.scss"],"names":[],"mappings":"AAEC,gFACC,aAAA,CACA,cAAA,CACA,eAAA,CAGD,uFACC,eAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CAGD,kFACC,YAAA,CACA,mBAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".savingDisplayOrder {\n\n\t.infoHeader {\n\t\tcolor: #21272A;\n\t\tfont-size: 14px;\n\t\tfont-weight: 500;\n\t}\n\n\t.questioningHeader {\n\t\tmargin-top: 16px;\n\t\tcolor: #21272A;\n\t\tfont-size: 14px;\n\t\tfont-weight: 400;\n\t}\n\n\t.actionsGroup {\n\t\tdisplay: flex;\n\t\tjustify-content: end;\n\t\tgap: 10px;\n\t\tmargin-top: 32px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"savingDisplayOrder": `SavingDisplayOrder__savingDisplayOrder-mwz`,
	"infoHeader": `SavingDisplayOrder__infoHeader-APs`,
	"questioningHeader": `SavingDisplayOrder__questioningHeader-Dlx`,
	"actionsGroup": `SavingDisplayOrder__actionsGroup-8Ej`
};
export default ___CSS_LOADER_EXPORT___;
