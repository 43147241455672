// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewOrderReset__viewOrderReset-lRR .ViewOrderReset__questioningHeader-Iuv{color:#21272a;font-size:14px;font-weight:400}.ViewOrderReset__viewOrderReset-lRR .ViewOrderReset__actionsGroup-EmV{display:flex;justify-content:end;gap:10px;margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/modules/AppSettings/Plugin/components/PSDisplayOrder/components/ViewOrderReset/viewOrderReset.module.scss"],"names":[],"mappings":"AAEC,2EACC,aAAA,CACA,cAAA,CACA,eAAA,CAGD,sEACC,YAAA,CACA,mBAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".viewOrderReset {\n\n\t.questioningHeader {\n\t\tcolor: #21272A;\n\t\tfont-size: 14px;\n\t\tfont-weight: 400;\n\t}\n\n\t.actionsGroup {\n\t\tdisplay: flex;\n\t\tjustify-content: end;\n\t\tgap: 10px;\n\t\tmargin-top: 32px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewOrderReset": `ViewOrderReset__viewOrderReset-lRR`,
	"questioningHeader": `ViewOrderReset__questioningHeader-Iuv`,
	"actionsGroup": `ViewOrderReset__actionsGroup-EmV`
};
export default ___CSS_LOADER_EXPORT___;
