import { ITransactionsFilterForm } from "api/transactionGroup";

export const getFilterInitState = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const walletHashID = Object.fromEntries(searchParams.entries()).walletHashID;

	if (walletHashID) {
		return { wallet_hash_id: walletHashID } as ITransactionsFilterForm;
	}

	return {} as ITransactionsFilterForm;
};
