import API from "api";
import { UpdateSortingParamsType } from "api/walletTypeToCurrencyGroup";
import { useTranslation } from "pay-kit";
import { useCallback, useState } from "react";
import { errorsMap } from "utils/enums";

const useUpdateSorting = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);
	const { t } = useTranslation();

	const update: UpdateHandler = useCallback((currencyCode) => {
		setIsLoading(true);
		setError(null);

		API.walletTypeToCurrency
			.updateSorting(currencyCode)
			.then((res) => {
				if (res.status === "success") {
					window.pushAlert({
						type: "success",
						title: t(`Success`),
						description: t(`Display order updated successfully`),
					});
				} else {
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		update,
		isLoading,
		error,
	};
};

export default useUpdateSorting;

type UpdateHandler = (params: UpdateSortingParamsType) => void;
