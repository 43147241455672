import API from "api";
import { CurrencyListWithDefaultSortingType } from "api/settingsGroup";
import { useCallback, useState } from "react";
import { errorsMap } from "utils/enums";

const useGetPaymentOfCurrencies = () => {
	const [list, setList] = useState<readonly CurrencyListWithDefaultSortingType[] | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const load = useCallback(() => {
		setIsLoading(true);
		setError(null);
		setList(null);

		API.settings
			.getPaymentOfCurrencies()
			.then((res) => {
				if (res.status === "ok") {
					setList(res.currency_list_with_default_sorting);
				} else {
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		load,
		list,
		isLoading,
		error,
	};
};

export default useGetPaymentOfCurrencies;
