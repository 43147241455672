import PageTitle from "components/PageTitle";
import RoutedTabs, { RoutedTab } from "components/RoutedTabs";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useTranslation } from "pay-kit";
import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";

const AppSettingsPage = () => {
	const { t } = useTranslation();
	const { hasRole } = useContext(AuthContext);
	const tabs: readonly RoutedTab[] = [
		{
			label: t("Profile"),
			value: "/settingsGroup/profile",
		},
		{
			// label: "Системные",
			label: t(`System settings`),
			value: "/settingsGroup/system",
			visible: hasRole(Roles.VIEW_SYSTEM_SETTINGS),
		},
		{
			label: t(`Confidential settings`),
			value: "/settingsGroup/private",
			visible: hasRole(Roles.PRIVATE_SETTINGS_VIEW),
		},
		{
			label: t(`Plugin`),
			value: "/settingsGroup/plugin",
			visible: hasRole(Roles.PLUGIN_SETTINGS),
		},
	];

	useEffect(() => {
		document.title = t("Settings");
	}, []);

	return (
		<>
			<PageTitle title={t("Settings")} />
			<RoutedTabs tabs={tabs} />
			<Outlet />
		</>
	);
};

export default AppSettingsPage;
